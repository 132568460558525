import * as PIXI from 'pixi.js';

import Animation from './animation';
import type { IAnimation } from './d';

class SpriteAnimation extends Animation {
  public spriteAnimation: PIXI.AnimatedSprite;

  constructor(options: IAnimation, textures: PIXI.Texture[]) {
    super(options);
    this.spriteAnimation = new PIXI.AnimatedSprite(textures);
    this.spriteAnimation.loop = true;
    if (!options.isLoop) {
      this.spriteAnimation.onLoop = this.onComplete.bind(this);
      this.addOnComplete(() => {
        this.spriteAnimation.stop();
      });
    }
    this.spriteAnimation.onComplete = this.onComplete.bind(this);
  }

  public override start(): void {
    super.start();
    this.spriteAnimation.play();
  }

  public override skip(): void {
    super.skip();
    this.spriteAnimation.stop();
  }
}

export default SpriteAnimation;
