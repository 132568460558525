import i18n from 'i18next';
import MultiStyleText from 'pixi-multistyle-text';
import { Loader, Sprite, Text, TextStyle, Texture } from 'pixi.js';

import { EventTypes, MessageBannerProps, MessageBannerTypes } from '../../global.d';
import type Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import { TweenProperties } from '../animations/d';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import Backdrop from '../backdrop/backdrop';
import ViewContainer from '../components/container';
import { eventManager } from '../config';

interface IOptions {
  text?: string;
  position?: number;
  styles?: MultiStyleText['textStyles'] | TextStyle;
}

class MessageBanner extends ViewContainer {
  private animation: SpineAnimation | null = null;

  private title: MultiStyleText;

  private subtitle: Text;

  private value: Text;

  private btn: MultiStyleText;

  private additionalText: MultiStyleText;

  private bindedHandleDestroy = this.handleDestroy.bind(this);

  private bannerBg: string;

  private disabled: boolean;

  backdrop: Backdrop;

  private props: MessageBannerProps;

  constructor(props: MessageBannerProps) {
    super();
    this.props = props;
    this.backdrop = new Backdrop();
    this.backdrop.show();
    this.bannerBg = props.bannerBg;

    this.visible = true;
    this.interactive = true;
    this.disabled = true;
    this.on('click', () => {
      this.startOutAnimation(`${this.bannerBg}_out`);
    });
    this.on('touchstart', () => {
      this.startOutAnimation(`${this.bannerBg}_out`);
    });
    this.title = this.initMultiText({
      ...props.title,
    });
    this.subtitle = this.initText({
      ...props.subtitle,
    });
    this.additionalText = this.initMultiText({
      ...props.additionalText,
    });
    this.btn = this.initMultiText({
      ...props.btn,
    });
    this.value = this.initText({
      ...props.value,
    });
    this.init();
    this.startInAnimation(`${this.bannerBg}_in`, `${this.bannerBg}_loop`);
    if (props.onInitCallback) {
      props.onInitCallback();
    }
    eventManager.addListener(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER, this.bindedHandleDestroy.bind(this));
    eventManager.addListener(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, () => {
      this.startOutAnimation(`${this.bannerBg}_out`);
    });
  }

  private init(): void {
    this.addChild(this.backdrop);
    this.initAnimation();
  }

  initAnimation(): void {
    this.animation = new SpineAnimation({}, Loader.shared.resources['popup']!.spineData!);
    this.fillPlaceholders();
    this.addChild(this.animation!.spine);
  }

  private startInAnimation(inAnim: string, loopAnim: string): void {
    this.toggleItemsVisibility(true);
    this.animation!.setAnimation(inAnim, false);
    this.animation!.start();

    this.animation!.complete = [];
    this.animation!.addOnComplete(() => {
      this.disabled = false;
      this.startLoopAnimation(loopAnim, true);
    });
  }

  private startLoopAnimation(anim: string, isLoop = false): void {
    this.animation!.setAnimation(anim, isLoop);
    this.animation!.start();

    this.animation!.complete = [];
  }

  private startOutAnimation(anim: string): void {
    if (!this.disabled) {
      this.disabled = true;
      this.toggleItemsVisibility(false);
      this.animation!.setAnimation(anim, false);
      this.animation!.start();
      this.animation!.complete = [];
      this.animation!.addOnComplete(() => {
        setTimeout(() => {
          this.handleClick(this.props);
        }, 0);
      });
    }
  }

  private startFadeAnimation(item: MultiStyleText | Text, beginValue: number, duration: number): void {
    const animationChain: AnimationChain = new AnimationChain();
    const fade = this.getFadeAnimation(item, beginValue, duration);
    animationChain.appendAnimation(fade);
    animationChain.start();
  }

  private getFadeAnimation(item: MultiStyleText | Text, alpha: number, duration: number): Animation {
    const animation = new Tween({
      object: item,
      duration,
      propertyBeginValue: alpha === 0 ? 0 : 1,
      target: alpha === 0 ? 1 : 0,
      property: TweenProperties.ALPHA,
    });

    return animation;
  }

  private handleClick(props: MessageBannerProps): void {
    if (props.callback) {
      props.callback();
      //@TODO
      // props.callback = undefined;
    }
    if (!props.preventDefaultDestroy) {
      this.handleDestroy();
    }
  }

  private toggleItemsVisibility(visibility: boolean): void {
    const alpha = visibility ? 0 : 1;
    const duration = visibility ? 1500 : 200;
    this.startFadeAnimation(this.title, alpha, duration);
    this.startFadeAnimation(this.subtitle, alpha, duration);
    this.startFadeAnimation(this.additionalText, alpha, duration);
    this.startFadeAnimation(this.btn, alpha, duration);
    this.startFadeAnimation(this.value, alpha, duration);
  }

  private initMultiText(options: IOptions): MultiStyleText {
    const text = new MultiStyleText(i18n.t(options.text || ''), options.styles);
    text.visible = !!options.text;
    text.anchor.set(0.5);
    text.alpha = 0;

    return text;
  }

  private initText(options: IOptions): Text {
    const text = new Text(i18n.t<string>(options.text || ''), options.styles as TextStyle);
    text.visible = !!options.text;
    text.anchor.set(0.5);
    text.alpha = 0;

    return text;
  }

  public handleDestroy(): void {
    eventManager.removeListener(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER, this.bindedHandleDestroy);
    eventManager.removeAllListeners(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);

    this.destroy({ children: false, texture: false, baseTexture: false });
  }

  public fillPlaceholders(): void {
    setTimeout(() => {
      const pressToContinue = this.animation!.getSpine().skeleton.findSlot('press_anywhere').currentSprite as Sprite;
      pressToContinue.texture = Texture.EMPTY;
      pressToContinue.addChild(this.btn);
      switch (this.props.type) {
        case MessageBannerTypes.Pickem:
          const pickemTitle = this.animation!.getSpine().skeleton.findSlot('you_won').currentSprite as Sprite;
          pickemTitle.texture = Texture.EMPTY;
          pickemTitle.addChild(this.title);
          const pickemText = this.animation!.getSpine().skeleton.findSlot('pick_em_bonus').currentSprite as Sprite;
          pickemText.texture = Texture.EMPTY;
          pickemText.addChild(this.subtitle);
          break;
        case MessageBannerTypes.Freespin:
          const freeSpinTitle = this.animation!.getSpine().skeleton.findSlot('you_won').currentSprite as Sprite;
          freeSpinTitle.texture = Texture.EMPTY;
          freeSpinTitle.addChild(this.title);

          const freeSpinText = this.animation!.getSpine().skeleton.findSlot('in_pick_em_bonus').currentSprite as Sprite;
          freeSpinText.texture = Texture.EMPTY;
          freeSpinText.addChild(this.subtitle);

          const freeSpinValue = this.animation!.getSpine().skeleton.findSlot('13_free_spins').currentSprite as Sprite;
          freeSpinValue.texture = Texture.EMPTY;
          freeSpinValue.addChild(this.value);
          break;
        case MessageBannerTypes.Total:
          const totalTitle = this.animation!.getSpine().skeleton.findSlot('congratulations').currentSprite as Sprite;
          totalTitle.texture = Texture.EMPTY;
          totalTitle.addChild(this.title);
          const totalText = this.animation!.getSpine().skeleton.findSlot('you_won_money').currentSprite as Sprite;
          totalText.texture = Texture.EMPTY;
          totalText.addChild(this.subtitle);
          const totalValue = this.animation!.getSpine().skeleton.findSlot('123456789').currentSprite as Sprite;
          totalValue.texture = Texture.EMPTY;
          totalValue.addChild(this.value);
          break;
        default:
          break;
      }
    }, 100);
  }
}

export default MessageBanner;
