import type { ITrackEntry } from 'pixi-spine';
import * as PIXI from 'pixi.js';
import { Loader } from 'pixi.js';

import { MAPPED_SYMBOLS, SlotId } from '../../config';
import SpineAnimation from '../animations/spine';
import { REEL_WIDTH, SLOT_HEIGHT, SLOT_WIDTH, WILD_SCALE } from '../config';

class StickyWild extends PIXI.Sprite {
  private animation: SpineAnimation | null = null;

  //   private isPlayedAnimation: boolean;

  private isSticky = false;

  constructor(row: number, col: number) {
    super(PIXI.Texture.from(MAPPED_SYMBOLS[SlotId.WL]));
    this.width = SLOT_WIDTH;
    this.height = SLOT_HEIGHT;
    this.x = col * REEL_WIDTH + (REEL_WIDTH - SLOT_WIDTH) / 2;
    this.y = this.yPosition(row, col);
    this.visible = false;
    // this.isPlayedAnimation = false;
    this.initAnimation();
  }

  private initAnimation(): void {
    this.animation = new SpineAnimation({}, Loader.shared.resources['symbols']!.spineData!);
    this.animation.spine.scale.set(WILD_SCALE);
    this.animation.spine.x = this.width / 2;
    this.animation.spine.y = this.height / 2;
    this.animation.addOnStart(() => {
      this.addChild(this.animation!.spine);
    });
    // eventManager.addListener(EventTypes.START_SPIN_ANIMATION, () => {
    //   if (this.isPlayedAnimation) {
    //     this.animation!.spine.visible = false;
    //     this.isPlayedAnimation = false;
    //   }
    // });
  }

  private startAnimation(anim: string): void {
    this.animation!.setAnimation(anim, false);
    this.animation!.start();
    this.animation!.complete = [];
    // this.animation!.addOnComplete(() => {
    //   this.isPlayedAnimation = true;
    // });
  }

  public onStick(multiplier: number): void {
    this.animation!.spine.visible = true;
    this.startAnimation(`wild_land_x${multiplier}`);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.animation?.spine.state.tracks[0] as any).animationStart = (
      this.animation?.spine.state.tracks[0] as ITrackEntry
    ).animationEnd;
  }

  private xPosition(_row: number, col: number): number {
    const positionValue = REEL_WIDTH;
    return col * positionValue;
  }

  private yPosition(row: number, _col: number): number {
    return SLOT_HEIGHT * row;
  }
}

export default StickyWild;
