import i18n from 'i18next';
import * as _ from 'lodash';
import { isMobile } from 'mobile-device-detect';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs } from '../../config';
import { EventTypes, FeatureTypes, IBonus } from '../../global.d';
import {
  setBetAmount,
  setBonuses,
  setCoinAmount,
  setCurrency,
  setIsBuyFeaturePopupOpened,
  setSlotConfig,
} from '../../gql';
import type { IBetSettings } from '../../gql/d';
import { getBetsSetting } from '../../gql/fromFragment';
import { ResourceTypes } from '../../resources.d';
import { normalizeCoins, showCurrency, updateTextScale } from '../../utils';
import ViewContainer from '../components/container';
import {
  FEATURE_POPUP_BET_TEXT_POSITION_X,
  FEATURE_POPUP_BET_TEXT_POSITION_Y,
  FEATURE_POPUP_BET_VALUE_POSITION_X,
  FEATURE_POPUP_BET_VALUE_POSITION_Y,
  FEATURE_POPUP_CANCEL_BTN_HEIGHT,
  FEATURE_POPUP_CANCEL_BTN_POSITION_X,
  FEATURE_POPUP_CANCEL_BTN_POSITION_Y,
  FEATURE_POPUP_CANCEL_BTN_WIDTH,
  FEATURE_POPUP_HEIGHT,
  FEATURE_POPUP_INPUT_HEIGHT,
  FEATURE_POPUP_INPUT_POSITION_X,
  FEATURE_POPUP_INPUT_POSITION_Y,
  FEATURE_POPUP_INPUT_WIDTH,
  FEATURE_POPUP_MINUS_BTN_HEIGHT,
  FEATURE_POPUP_MINUS_BTN_POSITION_X,
  FEATURE_POPUP_MINUS_BTN_POSITION_Y,
  FEATURE_POPUP_MINUS_BTN_WIDTH,
  FEATURE_POPUP_OK_BTN_HEIGHT,
  FEATURE_POPUP_OK_BTN_POSITION_X,
  FEATURE_POPUP_OK_BTN_POSITION_Y,
  FEATURE_POPUP_OK_BTN_WIDTH,
  FEATURE_POPUP_PLUS_BTN_HEIGHT,
  FEATURE_POPUP_PLUS_BTN_POSITION_X,
  FEATURE_POPUP_PLUS_BTN_POSITION_Y,
  FEATURE_POPUP_PLUS_BTN_WIDTH,
  FEATURE_POPUP_POSITION_X,
  FEATURE_POPUP_POSITION_Y,
  FEATURE_POPUP_TITLE_POSITION_X,
  FEATURE_POPUP_TITLE_POSITION_Y,
  FEATURE_POPUP_TOTAL_CONST_TEXT_AMOUNT_POSITION_X,
  FEATURE_POPUP_TOTAL_CONST_TEXT_AMOUNT_POSITION_Y,
  FEATURE_POPUP_TOTAL_CONST_TEXT_POSITION_X,
  FEATURE_POPUP_TOTAL_CONST_TEXT_POSITION_Y,
  FEATURE_POPUP_WIDTH,
  eventManager,
} from '../config';

import BuyFeaturePopupConfirm from './buyFeaturePopupConfirm';
import {
  betTextStyle,
  betValueStyle,
  buyFeatureTitleStyle,
  totalCostTextAmountStyle,
  totalCostTextStyle,
} from './textStyles';

class BuyFeaturePopup extends ViewContainer {
  private popupBg: PIXI.Sprite;

  private okBtn: PIXI.Sprite;

  private cancelBtn: PIXI.Sprite;

  private titleText: PIXI.Text;

  private totalCostText: PIXI.Text;

  private totalCostTextAmount: PIXI.Text;

  private betText: PIXI.Text;

  private minusBtn: PIXI.Sprite;

  private plusBtn: PIXI.Sprite;

  private input: PIXI.Sprite;

  private bee: PIXI.Sprite;

  private betSettings: IBetSettings;

  private betAmount: number;

  private linesAmount: number;

  private currency = 'FUN';

  private betValue: PIXI.Text;

  private buyFeaturePopupConfirm: BuyFeaturePopupConfirm;

  isNoFunds: boolean;

  balance: number;

  constructor(lines: number[][]) {
    super();
    this.x = FEATURE_POPUP_POSITION_X;
    this.y = FEATURE_POPUP_POSITION_Y;
    this.betSettings = getBetsSetting();
    this.visible = false;
    this.linesAmount = lines.length;
    this.balance = 0;
    this.isNoFunds = false;
    this.interactive = true;
    this.currency = setCurrency();
    this.betAmount = this.getBetAmount(setBetAmount());
    this.bee = this.initBee();
    this.popupBg = this.initPopupBg();
    this.titleText = this.initTitle();
    this.totalCostText = this.initTotalCostText();
    this.totalCostTextAmount = this.initTotalCostTextAmount();
    this.betText = this.initBetText();
    this.minusBtn = this.initMinusBtn();
    this.plusBtn = this.initPlusBtn();
    this.input = this.initInput();
    this.betValue = this.initBetValue();
    this.cancelBtn = this.initCancelBtn();
    this.okBtn = this.initOkBtn();
    this.buyFeaturePopupConfirm = new BuyFeaturePopupConfirm();
    this.init();
  }

  private init(): void {
    this.addChild(this.popupBg);
    this.addChild(this.titleText);
    this.addChild(this.totalCostText);
    this.addChild(this.totalCostTextAmount);
    this.addChild(this.betText);
    this.addChild(this.minusBtn);
    this.addChild(this.plusBtn);
    this.addChild(this.input);
    this.addChild(this.betValue);
    this.addChild(this.okBtn);
    this.addChild(this.cancelBtn);
    this.addChild(this.bee);
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, () => {
      this.closeAllAnimationsInSlot();
      this.visible = true;
      setIsBuyFeaturePopupOpened(true);
    });
    eventManager.on(EventTypes.UPDATE_BET, () => {
      this.betAmount = this.getBetAmount(setBetAmount());
      this.updateBets();
      this.handleDisable();
    });
    eventManager.on(EventTypes.START_BUY_FEATURE_ROUND, () => {
      this.visible = false;
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP);
      eventManager.emit(EventTypes.HIDE_BACKDROP);
      setIsBuyFeaturePopupOpened(false);
    });
    eventManager.on(EventTypes.UPDATE_USER_BALANCE, (balance: { currency: string; amount: number }) => {
      this.balance = balance.amount / 100;
      this.handleDisable();
    });
    eventManager.on(EventTypes.FORCE_CLOSE_BUYFEATURE, () => {
      this.onCancel();
    });
  }

  private initPopupBg(): PIXI.Sprite {
    const popupBg = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeaturePopup));
    popupBg.width = FEATURE_POPUP_WIDTH;
    popupBg.height = FEATURE_POPUP_HEIGHT;

    return popupBg;
  }

  private initBee(): PIXI.Sprite {
    const bee = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureBee));
    bee.x = 850;
    bee.y = 200;
    return bee;
  }

  private initTitle(): PIXI.Text {
    const title = new PIXI.Text(i18n.t('buyFeatureTitle') as string, {
      ...buyFeatureTitleStyle,
      dropShadow: true,
      dropShadowAlpha: 0.1,
      dropShadowAngle: 1.4,
      dropShadowBlur: 5,
      dropShadowDistance: 6,
    });
    updateTextScale(title, isMobile ? 450 : 500, isMobile ? 200 : 250);
    title.y = FEATURE_POPUP_TITLE_POSITION_Y;
    title.x = FEATURE_POPUP_TITLE_POSITION_X;
    title.anchor.set(0.5, 0);

    return title;
  }

  private initTotalCostText(): PIXI.Text {
    const totalCostText = new PIXI.Text(i18n.t('buyFeatureTotalCost') as string, totalCostTextStyle);
    totalCostText.y = FEATURE_POPUP_TOTAL_CONST_TEXT_POSITION_Y;
    totalCostText.x = FEATURE_POPUP_TOTAL_CONST_TEXT_POSITION_X;
    totalCostText.anchor.set(0.5, 0);

    return totalCostText;
  }

  private initTotalCostTextAmount(): PIXI.Text {
    const totalCostTextAmount = new PIXI.Text(this.getTotalCost(), totalCostTextAmountStyle);
    totalCostTextAmount.y = FEATURE_POPUP_TOTAL_CONST_TEXT_AMOUNT_POSITION_Y;
    totalCostTextAmount.x = FEATURE_POPUP_TOTAL_CONST_TEXT_AMOUNT_POSITION_X;
    totalCostTextAmount.anchor.set(0.5, 0);

    return totalCostTextAmount;
  }

  private initBetText(): PIXI.Text {
    const betText = new PIXI.Text(i18n.t('buyFeatureBetPerGame') as string, betTextStyle);
    betText.y = FEATURE_POPUP_BET_TEXT_POSITION_Y;
    betText.x = FEATURE_POPUP_BET_TEXT_POSITION_X;
    betText.anchor.set(0.5, 0);

    return betText;
  }

  private initMinusBtn(): PIXI.Sprite {
    const minusBtn = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureMinusBtn));
    minusBtn.width = FEATURE_POPUP_MINUS_BTN_WIDTH;
    minusBtn.height = FEATURE_POPUP_MINUS_BTN_HEIGHT;
    minusBtn.y = FEATURE_POPUP_MINUS_BTN_POSITION_Y;
    minusBtn.x = FEATURE_POPUP_MINUS_BTN_POSITION_X;
    minusBtn.interactive = true;
    minusBtn.on('pointerdown', () => this.handleMinus());
    minusBtn.addListener('mouseover', () => {
      AudioApi.play({ type: ISongs.SFX_UI_Hover });
      this.minusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureMinusBtnHover);
    });
    minusBtn.addListener('mouseout', () => {
      this.minusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureMinusBtn);
    });
    minusBtn.addListener('mouseup', () => {
      this.minusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureMinusBtnHover);
    });

    return minusBtn;
  }

  private initPlusBtn(): PIXI.Sprite {
    const plusBtn = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeaturePlusBtn));
    plusBtn.width = FEATURE_POPUP_PLUS_BTN_WIDTH;
    plusBtn.height = FEATURE_POPUP_PLUS_BTN_HEIGHT;
    plusBtn.y = FEATURE_POPUP_PLUS_BTN_POSITION_Y;
    plusBtn.x = FEATURE_POPUP_PLUS_BTN_POSITION_X;
    plusBtn.interactive = true;
    plusBtn.on('pointerdown', () => this.handlePlus());
    plusBtn.addListener('mouseover', () => {
      AudioApi.play({ type: ISongs.SFX_UI_Hover });
      this.plusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeaturePlusBtnHover);
    });
    plusBtn.addListener('mouseout', () => {
      this.plusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeaturePlusBtn);
    });
    plusBtn.addListener('mouseup', () => {
      this.plusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeaturePlusBtnHover);
    });

    return plusBtn;
  }

  private initInput(): PIXI.Sprite {
    const input = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureInput));
    input.width = FEATURE_POPUP_INPUT_WIDTH;
    input.height = FEATURE_POPUP_INPUT_HEIGHT;
    input.y = FEATURE_POPUP_INPUT_POSITION_Y;
    input.x = FEATURE_POPUP_INPUT_POSITION_X;

    return input;
  }

  private initBetValue(): PIXI.Text {
    const betValue = new PIXI.Text(
      `${formatNumber({
        currency: this.currency,
        value: normalizeCoins(this.getBetValue()),
        showCurrency: showCurrency(this.currency),
      })}`,
      betValueStyle,
    );
    betValue.y = FEATURE_POPUP_BET_VALUE_POSITION_Y;
    betValue.x = FEATURE_POPUP_BET_VALUE_POSITION_X;
    betValue.anchor.set(0.5, 0);

    return betValue;
  }

  private initCancelBtn(): PIXI.Sprite {
    const cancelBtn = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtn));
    cancelBtn.width = FEATURE_POPUP_CANCEL_BTN_WIDTH;
    cancelBtn.height = FEATURE_POPUP_CANCEL_BTN_HEIGHT;
    cancelBtn.y = FEATURE_POPUP_CANCEL_BTN_POSITION_Y;
    cancelBtn.x = FEATURE_POPUP_CANCEL_BTN_POSITION_X;
    cancelBtn.interactive = true;
    cancelBtn.on('click', () => this.onCancel());
    cancelBtn.on('touchstart', () => this.onCancel());

    cancelBtn.addListener('mouseover', () => {
      AudioApi.play({ type: ISongs.SFX_UI_Hover });
      this.cancelBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtnHover);
    });
    cancelBtn.addListener('mouseout', () => {
      this.cancelBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtn);
    });
    cancelBtn.addListener('mousedown', () => {
      this.cancelBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtnPressed);
    });
    cancelBtn.addListener('mouseup', () => {
      this.cancelBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtn);
    });
    return cancelBtn;
  }

  private onCancel(): void {
    AudioApi.play({ type: ISongs.SFX_UI_Close });
    this.visible = false;
    setIsBuyFeaturePopupOpened(false);
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP);
    eventManager.emit(EventTypes.HIDE_BACKDROP);
  }

  private initOkBtn(): PIXI.Sprite {
    const okBtn = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureOkBtn));
    okBtn.width = FEATURE_POPUP_OK_BTN_WIDTH;
    okBtn.height = FEATURE_POPUP_OK_BTN_HEIGHT;
    okBtn.y = FEATURE_POPUP_OK_BTN_POSITION_Y;
    okBtn.x = FEATURE_POPUP_OK_BTN_POSITION_X;
    okBtn.interactive = true;
    okBtn.on('click', (): void => this.handleClickOk());
    okBtn.on('touchstart', (): void => this.handleClickOk());

    okBtn.addListener('mouseover', () => {
      if (!this.isNoFunds) {
        AudioApi.play({ type: ISongs.SFX_UI_Hover });
        this.okBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureOkBtnHover);
      }
    });
    okBtn.addListener('mouseout', () => {
      if (!this.isNoFunds) {
        this.okBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureOkBtn);
      }
    });
    okBtn.addListener('mousedown', () => {
      if (!this.isNoFunds) {
        this.okBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureOkBtnPressed);
      }
    });
    okBtn.addListener('mouseup', () => {
      if (!this.isNoFunds) {
        this.okBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureOkBtn);
      }
    });

    return okBtn;
  }

  private getBetAmount(betAmount: number): number {
    return (
      _.findIndex(this.betSettings!.bets, (bet) => {
        return bet === betAmount / this.linesAmount;
      }) + 1
    );
  }

  private handleMinus(): void {
    if (this.betSettings.bets[this.betAmount - 1]! > this.betSettings!.minBet) {
      this.minusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureMinusBtnPressed);
      // eslint-disable-next-line no-plusplus
      this.betAmount--;
      setCoinAmount(this.betSettings.bets[this.betAmount - 1]);
      this.updateBets();
      this.handleDisable();
      setBetAmount(setCoinAmount() * setSlotConfig().lines.length);
      AudioApi.play({ type: ISongs.SFX_UI_BetChange });
    }
  }

  private handlePlus(): void {
    if (this.betSettings.bets[this.betAmount - 1]! < this.betSettings!.maxBet) {
      this.plusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeaturePlusBtnPressed);
      // eslint-disable-next-line no-plusplus
      this.betAmount++;
      setCoinAmount(this.betSettings.bets[this.betAmount - 1]);
      this.updateBets();
      this.handleDisable();
      setBetAmount(setCoinAmount() * setSlotConfig().lines.length);
      AudioApi.play({ type: ISongs.SFX_UI_BetChange });
    }
  }

  private updateBets(): void {
    this.totalCostTextAmount.text = this.getTotalCost();
    this.betValue.text = `${formatNumber({
      currency: this.currency,
      value: normalizeCoins(this.getBetValue()),
      showCurrency: showCurrency(this.currency),
    })}`;
  }

  private getTotalCost(): string {
    return `${formatNumber({
      currency: this.currency,
      value: normalizeCoins(this.getBetValue() * this.getCoinAmount()),
      showCurrency: showCurrency(this.currency),
    })}`;
  }

  private getBetValue(): number {
    return this.linesAmount * (this.betSettings!.bets[this.betAmount - 1] || 1);
  }

  private getCoinAmount(): number {
    const bonuses = setBonuses();
    const bonus = _.chain(bonuses)
      .filter((bonus) => bonus.type === FeatureTypes.SPECIAL_ROUND)
      .get(0, {})
      .value() as IBonus;

    return bonus.coinAmount;
  }

  private handleClickOk(): void {
    if (!this.isNoFunds) {
      AudioApi.play({ type: ISongs.SFX_UI_General });
      eventManager.emit(
        EventTypes.OPEN_BUY_FEATURE_CONFIRM_POPUP,
        this.getTotalCost(),
        this.betSettings.bets[this.betAmount],
      );
    }
  }

  private handleDisable(): void {
    const bet = this.betSettings.bets[this.betAmount - 1];
    this.isNoFunds = this.balance < normalizeCoins(this.getBetValue() * this.getCoinAmount());
    const res = this.isNoFunds ? ResourceTypes.buyFeatureOkBtnDisabled : ResourceTypes.buyFeatureOkBtn;

    if (bet === this.betSettings!.minBet) {
      this.minusBtn.interactive = false;
      this.minusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureMinusBtnDisabled);
    } else {
      this.minusBtn.interactive = true;
      this.minusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureMinusBtn);
    }

    if (bet === this.betSettings!.maxBet) {
      this.plusBtn.interactive = false;
      this.plusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeaturePlusBtnDisabled);
    } else {
      this.plusBtn.interactive = true;
      this.plusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeaturePlusBtn);
    }

    this.okBtn.texture = PIXI.Texture.from(res);
  }

  private closeAllAnimationsInSlot() {
    eventManager.emit(EventTypes.SET_EPIC_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
  }
}

export default BuyFeaturePopup;
