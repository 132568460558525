import { useReactiveVar } from '@apollo/client';
import type React from 'react';
import { useTranslation } from 'react-i18next';

import { formatNumber } from '@phoenix7dev/utils-fe';

import { gameRules } from '../../config/gameRules';
import { setCurrency, setSlotConfig } from '../../gql/cache';
import { getBetsSetting } from '../../gql/fromFragment';
import { isBuyFeatureEnabled } from '../../utils';

import styles from './info.module.scss';

const GameRules: React.FC = () => {
  const { t } = useTranslation();
  const currency = useReactiveVar(setCurrency);
  // const { isBuyFeatureEnabled } = useReactiveVar<ISlotConfig>(setSlotConfig);

  const betSettings = getBetsSetting();
  const minBet = formatNumber({ currency, value: betSettings.minBetCount });
  const maxBet = formatNumber({ currency, value: betSettings.maxBetCount });

  return (
    <div className={styles['gameRules']}>
      <h1 className={styles['title']}>{t('infoGameRulesTitle')}</h1>
      <div className={styles['row']}>
        <div className={styles['col']}>
          {gameRules
            .filter((v) => isBuyFeatureEnabled || !v.buyFeatureOnly)
            .map((v) => (
              <p className={`${styles['p']}`} key={v.key} dangerouslySetInnerHTML={{ __html: t(v.key) }} />
            ))}
        </div>
      </div>

      <p
        className={`${styles['p']} ${styles['center']}`}
        dangerouslySetInnerHTML={{
          __html: t('infoGameRules2', {
            minBet,
          }),
        }}
      />
      <p
        className={`${styles['p']} ${styles['center']}`}
        dangerouslySetInnerHTML={{
          __html: t('infoGameRules3', {
            maxBet,
          }),
        }}
      />

      <p
        className={`${styles['p']} ${styles['center']}`}
        dangerouslySetInnerHTML={{
          __html: t('infoGameRules4', {
            rtp: '96.55%',
          }),
        }}
      />
      {setSlotConfig().isBuyFeatureEnabled && (
        <>
          <p
            className={`${styles['p']} ${styles['center']}`}
            dangerouslySetInnerHTML={{
              __html: t('infoGameRules5', {
                rtp: '96.66%',
              }),
            }}
          />
        </>
      )}
      <p
        className={`${styles['p']} ${styles['center']}`}
        dangerouslySetInnerHTML={{
          __html: t('infoGameRules6', {
            version: window.__ENV__.APP_VERSION,
          }),
        }}
      />
    </div>
  );
};

export default GameRules;
