import 'pixi-spine';
import * as PIXI from 'pixi.js';
import { Sprite, Texture } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes, FreeSpinsTitleProps, GameMode, MessageBannerProps } from '../../global.d';
import { setCurrentBonus } from '../../gql';
import { setBrokenPickem } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import {
  isFreeSpinMode,
  isMobileLandscape,
  isMobilePortrait,
  isPickemMode,
  isTabletLandscape,
  isTabletPortrait,
} from '../../utils';
import SpineAnimation from '../animations/spine';
import ViewContainer from '../components/container';
import {
  ANTICIPATION_DURATION,
  GAME_CONTAINER_HEIGHT,
  GAME_CONTAINER_WIDTH,
  LEFT_LINES_NUMBERS_CONTAINER_POSITIONS,
  LINES_NUMBER_CONTAINER_HEIGHT,
  LINES_NUMBER_CONTAINER_WIDTH,
  RIGHT_LINES_NUMBERS_CONTAINER,
  SHOW_LINES_NUMBER_CONTAINER,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  eventManager,
} from '../config';
import type { IGameContainer } from '../d';
import FreeSpinsTitle from '../freeSpinsTitle/freeSpinsTitle';
import MessageBanner from '../messageBanner/messageBanner';
import PickemContainer from '../pickem/pickemContainer';

class GameView extends ViewContainer {
  public linesContainer: PIXI.Container;

  public leftLinesContainer: PIXI.Container | null = null;

  public rightLinesContainer: PIXI.Container | null = null;

  public winSlotsContainer: PIXI.Container;

  public miniPayTableContainer: PIXI.Container;

  public stickyWildsContainer: PIXI.Container;

  public reelsContainer: PIXI.Container;

  public tintContainer: PIXI.Container;

  public slotsContainer: PIXI.Container;

  public winLabelContainer: PIXI.Container;

  public winCountUpMessage: PIXI.Container;

  public logo: PIXI.Sprite;

  public frame: PIXI.Sprite;

  public freeSpinsTitle: FreeSpinsTitle | undefined;

  public maskArea: PIXI.Graphics;

  public anticipationAnimation: SpineAnimation | undefined;

  public anticipationAnimationContainer = new PIXI.Container();

  private isForceStopped = false;

  constructor(props: IGameContainer) {
    super();
    this.width = GAME_CONTAINER_WIDTH;
    this.height = GAME_CONTAINER_HEIGHT;
    this.sortableChildren = true;
    this.slotsContainer = new PIXI.Container();
    this.slotsContainer.x = -SLOTS_CONTAINER_WIDTH / 2;
    this.slotsContainer.y = -SLOTS_CONTAINER_HEIGHT / 2 + 20;
    this.slotsContainer.sortableChildren = true;
    this.maskArea = new PIXI.Graphics()
      .beginFill(0xffffff)
      .drawRect(0, 0, SLOTS_CONTAINER_WIDTH + 50, SLOTS_CONTAINER_HEIGHT)
      .endFill();
    this.maskArea.x = -25;
    this.slotsContainer.scale.set(1, 1);
    this.slotsContainer.interactive = true;
    this.logo = this.initGameLogo();
    if (SHOW_LINES_NUMBER_CONTAINER) {
      this.leftLinesContainer = new PIXI.Container();
      this.leftLinesContainer.width = LINES_NUMBER_CONTAINER_WIDTH;
      this.leftLinesContainer.height = LINES_NUMBER_CONTAINER_HEIGHT;
      this.rightLinesContainer = new PIXI.Container();
      this.rightLinesContainer.width = LINES_NUMBER_CONTAINER_WIDTH;
      this.rightLinesContainer.height = LINES_NUMBER_CONTAINER_HEIGHT;
      this.initLinesNumberContainers();
    }
    this.winLabelContainer = props.winLabelContainer;
    this.winSlotsContainer = props.winSlotsContainer;
    this.winSlotsContainer.x = this.slotsContainer.x;
    this.winSlotsContainer.y = this.slotsContainer.y;
    this.miniPayTableContainer = props.miniPayTableContainer;
    this.miniPayTableContainer.x = this.slotsContainer.x;
    this.miniPayTableContainer.y = this.slotsContainer.y;
    this.stickyWildsContainer = props.stickyWildsContainer;
    this.stickyWildsContainer.x = this.slotsContainer.x;
    this.stickyWildsContainer.y = this.slotsContainer.y;
    this.linesContainer = props.linesContainer;
    this.tintContainer = props.tintContainer;
    this.reelsContainer = props.reelsContainer;
    this.winCountUpMessage = props.winCountUpMessage;
    this.slotsContainer.addChild(this.tintContainer);
    this.slotsContainer.addChild(this.linesContainer);
    this.slotsContainer.addChild(this.reelsContainer);
    this.slotsContainer.addChild(this.maskArea);
    this.slotsContainer.mask = this.maskArea;
    this.frame = this.initReelsFrame();
    this.addChild(this.logo);
    this.addChild(this.frame);
    this.addChild(this.slotsContainer);
    this.addChild(this.miniPayTableContainer);
    this.addChild(this.stickyWildsContainer);
    this.addChild(this.logo);
    this.addChild(this.winSlotsContainer);
    this.addChild(this.winLabelContainer);
    this.addChild(this.winCountUpMessage);

    if (setBrokenPickem()) {
      this.miniPayTableContainer.visible = false;
      this.slotsContainer.visible = false;
      this.frame.visible = false;
      this.addChild(new PickemContainer(setCurrentBonus().data.preLoadedGrantBreakdown));
    }
    this.createReelAnticipation();

    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.resize.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onModeChange.bind(this));
    eventManager.addListener(EventTypes.CREATE_FREE_SPINS_TITLE, this.createFreeSpinsTitle.bind(this));
    eventManager.addListener(EventTypes.REMOVE_FREE_SPINS_TITLE, this.removeFreeSpinsTitle.bind(this));
    eventManager.addListener(EventTypes.CREATE_MESSAGE_BANNER, this.createFreeSpinsMessage.bind(this));
    eventManager.addListener(EventTypes.FORCE_STOP_REELS, () => {
      this.endReelAnticipation();
    });
    eventManager.addListener(EventTypes.SET_IS_SLOTS_STOPPED, (isStopped: boolean) => {
      this.isForceStopped = isStopped;
    });
    eventManager.addListener(EventTypes.REELS_STOPPED, () => {
      this.isForceStopped = false;
    });
    eventManager.addListener(EventTypes.ANTICIPATION_STARTS, this.onAnticipationStart.bind(this));
  }

  private initGameLogo(): Sprite {
    const logo = new Sprite(Texture.from(ResourceTypes.gameLogo));
    logo.x = -260;
    logo.y = -480;
    return logo;
  }

  private createFreeSpinsMessage(props: MessageBannerProps): void {
    this.addChild(new MessageBanner(props));
  }

  private removeFreeSpinsTitle(_props: FreeSpinsTitleProps): void {
    if (this.freeSpinsTitle) this.removeChild(this.freeSpinsTitle);
  }

  private createFreeSpinsTitle(props: FreeSpinsTitleProps): void {
    this.freeSpinsTitle = new FreeSpinsTitle(props);
    this.addChildAt(this.freeSpinsTitle, 3);
  }

  private createReelAnticipation(): void {
    const animation = new SpineAnimation({}, PIXI.Loader.shared.resources['anticipation']!.spineData!);
    animation.spine.x = 500;
    animation.spine.y = 15;
    animation.getSpine().state.timeScale = 1;
    animation.addOnComplete(() => {
      this.isForceStopped = false;
      this.anticipationAnimationContainer.visible = true;
    });
    this.anticipationAnimation = animation;
    this.anticipationAnimationContainer.addChild(animation.spine);
    this.addChild(this.anticipationAnimationContainer);
  }

  private endReelAnticipation(): void {
    this.isForceStopped = false;
    this.anticipationAnimationContainer.visible = false;
    AudioApi.stop({ type: ISongs.LongSpin });
    setTimeout(() => {
      this.anticipationAnimationContainer.visible = true;
    }, ANTICIPATION_DURATION);
  }

  private onAnticipationStart(): void {
    if (!this.isForceStopped) {
      this.anticipationAnimationContainer.visible = true;
      this.anticipationAnimation!.setAnimation('anticipation', false);
      AudioApi.play({ type: ISongs.LongSpin, stopPrev: true });
    }
  }

  private onModeChange(settings: { mode: GameMode }): void {
    if (isFreeSpinMode(settings.mode)) {
      this.logo.visible = false;
      this.slotsContainer.visible = true;
      this.frame.visible = true;
    } else if (isPickemMode(settings.mode)) {
      this.logo.visible = false;
      this.miniPayTableContainer.visible = false;
      this.slotsContainer.visible = false;
      this.frame.visible = false;
      this.addChild(new PickemContainer(setCurrentBonus().data.preLoadedGrantBreakdown));
    } else {
      this.miniPayTableContainer.visible = true;
      this.slotsContainer.visible = true;
      this.logo.visible = true;
      this.frame.visible = true;
    }
  }

  private initReelsFrame() {
    const frame = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.frame));
    frame.y = 0;
    frame.x = 0;
    frame.anchor.set(0.5);
    return frame;
  }

  public initLinesNumberContainers(): void {
    LEFT_LINES_NUMBERS_CONTAINER_POSITIONS.forEach((line) => {
      const sprite = new PIXI.Sprite(PIXI.Texture.EMPTY);
      sprite.interactive = true;
      sprite.x = line.x;
      sprite.y = line.y;
      sprite.on('mouseover', () => {
        eventManager.emit(EventTypes.SHOW_WIN_LINES, [{ lineId: line.id }]);
      });
      sprite.on('mouseout', () => {
        eventManager.emit(EventTypes.HIDE_WIN_LINES, [{ lineId: line.id }]);
      });
      sprite.addChild(new PIXI.Text(`${line.id + 1}`));
      this.leftLinesContainer!.addChild(sprite);
    });
    this.leftLinesContainer!.x = 0;
    this.addChild(this.leftLinesContainer!);

    RIGHT_LINES_NUMBERS_CONTAINER.forEach((line) => {
      const sprite = new PIXI.Sprite(PIXI.Texture.EMPTY);
      sprite.interactive = true;
      sprite.x = line.x;
      sprite.y = line.y;
      sprite.on('mouseover', () => {
        eventManager.emit(EventTypes.SHOW_WIN_LINES, [{ lineId: line.id }]);
      });
      sprite.on('mouseout', () => {
        eventManager.emit(EventTypes.HIDE_WIN_LINES, [{ lineId: line.id }]);
      });
      sprite.addChild(new PIXI.Text(`${line.id + 1}`));
      this.rightLinesContainer!.addChild(sprite);
    });
    this.rightLinesContainer!.x = SLOTS_CONTAINER_WIDTH + LINES_NUMBER_CONTAINER_WIDTH;
    this.addChild(this.rightLinesContainer!);
  }

  private resize(width: number, height: number, deviceWidth: number, deviceHeight: number): void {
    this.scale.set(width / 1500);
    this.y = 0;

    if (isTabletLandscape(deviceWidth, deviceHeight)) {
      this.scale.set(width / 1750);
    }
    if (isTabletPortrait(deviceWidth, deviceHeight)) {
      this.scale.set(width / 1650);
      this.y = -height * 0.15;
    }
    if (isMobilePortrait(deviceWidth, deviceHeight)) {
      this.scale.set(width / 1510);
      this.y = -height * 0.1;
    }
    if (isMobileLandscape(deviceWidth, deviceHeight)) {
      this.scale.set(width / 1450);
    }
    this.x = width / 2;
  }
}

export default GameView;
