import * as PIXI from 'pixi.js';

import { SlotId } from '../../config';

export * from './textStyles';
export const eventManager = new PIXI.utils.EventEmitter();
window.eventManager = eventManager; // Trick to proxy eventManager to cheats file
export enum SlotMachineState {
  IDLE,
  SPIN,
  STOP,
  JINGLE,
  WINNING,
  RETRIGGER,
  PICKEM,
}
// APPLICATION SETTINGS
export const APPLICATION_TRANSPARENT = true;
export const APPLICATION_FPS = 60;

// WIN SETTINGS
export const WIN_ANIMATION_DELAY_TIME_DURATION = 2_000;
export const WIN_CHECK_TIME_DURATION_ON_FREESPIN = 1_000;
export const BIG_WIN_CHECK_TIME_DURATION_ON_FREESPIN = 3_000;

// BASE WIN SETTINGS
export const DOUBLE_WIN_AMOUNT_LIMIT = 2;
export const BASE_WIN_AMOUNT_LIMIT = 10;
export const BASE_WIN_TITLE_SCALE = 1;
export const BASE_WIN_COUNT_UP_MULTIPLIER = 0.03;

// BIG WIN SETTINGS
export const BIG_WIN_AMOUNT_LIMIT = 25;
export const BIG_WIN_COUNT_UP_MULTIPLIER = 0.03;

// MEGA WIN SETTINGS
export const MEGA_WIN_AMOUNT_LIMIT = 40;
export const MEGA_WIN_COUNT_UP_MULTIPLIER = 0.1;

// GREAT WIN SETTINGS
export const GREAT_WIN_AMOUNT_LIMIT = 55;
export const GREAT_WIN_COUNT_UP_MULTIPLIER = 0.2;

// EPIC WIN SETTINGS
export const EPIC_WIN_COUNT_UP_MULTIPLIER = 0.5;

// WIN SLOT ANIMATION SETTINGS
export const WIN_SLOT_ANIMATION_SCALE = 1.15;
export const WIN_SLOT_ANIMATION_DURATION = 600;
export const TURBO_SPIN_WIN_SLOT_ANIMATION_COEFFICIENT = 1;
export const SPRITE_ANIMATION_FPS = 30;

// GENERAL SLOT MACHINE SETTINGS
export const REELS_AMOUNT = 5;
export const SLOTS_PER_REEL_AMOUNT = 3;
export const REEL_WIDTH = 250;
export const SLOT_WIDTH = 250;
export const SLOT_HEIGHT = 250;
export const SHOW_ALL_LINES_ON_WIN = true;
export const GAME_CONTAINER_POSITION_Y = 170;
export const GAME_CONTAINER_HEIGHT = SLOT_HEIGHT * SLOTS_PER_REEL_AMOUNT;
export const SLOTS_CONTAINER_WIDTH = REEL_WIDTH * REELS_AMOUNT;
export const SLOTS_CONTAINER_HEIGHT = SLOT_HEIGHT * SLOTS_PER_REEL_AMOUNT;
export const SHOW_LINES_NUMBER_CONTAINER = false;
export const LINES_NUMBER_CONTAINER_WIDTH = 30;
export const MINIMUM_SPIN_SLOTS_AMOUNT = 140;
export const GAME_CONTAINER_WIDTH = SHOW_LINES_NUMBER_CONTAINER
  ? SLOTS_CONTAINER_WIDTH + 2 * LINES_NUMBER_CONTAINER_WIDTH
  : SLOTS_CONTAINER_WIDTH;

// ANTICIPATION SETTINGS
export const ANTICIPATION_ENABLE = true;
export const ANTICIPATION_SYMBOLS_ID = [SlotId.SC1];
export const ANTICIPATION_SYMBOLS_AMOUNT = [2];
export const ANTICIPATION_DURATION = 3000;
export const ANTICIPATION_REEL_ENDING_SLOTS_AMOUNT = 15;
export const ANTICIPATION_SLOTS_TINT = 0xaaaaaa;

// SAFE AREA SETTINGS
export const SAFE_AREA_DESKTOP_BOTTOM_PADDING = 36;
export const SAFE_AREA_MOBILE_BOTTOM_PADDING = 0;

// REELS SETTINGS
export const BASE_SPIN_TIME = 2000;
export const TURBO_SPIN_TIME = 1000;
export const ADDITIONAL_SPIN_TIME_PER_REEL = 300;
export const TURBO_ADDITIONAL_SPIN_TIME_PER_REEL = 100;
export const TURBO_SPIN_REEL_ANIMATION_DELAY_PER_REEL = 25;
export const SPIN_REEL_ANIMATION_DELAY_PER_REEL = 50;
export const BASE_REEL_BLUR_VALUE = 18;
export const TURBO_REEL_BLUR_VALUE = 24;
export enum ReelState {
  IDLE,
  STARTING,
  ROLLING,
  ENDING,
}

// COUNT UP
export const COUNT_UP_MESSAGE_X = 0;
export const COUNT_UP_MESSAGE_Y = 315;
// NUMBER FORMAT
export const MINIMUM_FRACTION_DIGITS = 2;
export const MAXIMUM_FRACTION_DIGITS = 2;
// SPIN ANIMATION SETTINGS
export const INIT_SLOTS_AMOUNT_SPIN_BEFORE_STOP = 25;
export const BASE_REEL_STARTING_DURATION = 500;
export const BASE_REEL_ROLLING_DURATION = 1250;
export const BASE_REEL_ENDING_DURATION = 250;
export const FAKE_ROLLING_DURATION = 13_000;
export const FAKE_ROLLING_SLOTS = 400;
export const TURBO_REEL_STARTING_DURATION = 250;
export const TURBO_REEL_ROLLING_DURATION = 500;
export const TURBO_REEL_ENDING_DURATION = 250;
export const FORCE_STOP_SPIN_ANIMATION_DURATION = 250;
export const FORCE_STOP_SPIN_PER_EACH_DURATION = 50;
export const REEL_STARTING_SLOTS_AMOUNT = 3;
export const REEL_ENDING_SLOTS_AMOUNT = 3;
export const BASE_REEL_STARTING_FORMULA = (t: number): number => 3 * t * t * (1.4 * t - 0.4);
export const BASE_REEL_ROLLING_FORMULA = (t: number): number => -0.592691 + 1.77055 * t;
export const BASE_REEL_ENDING_FORMULA = (t: number): number => (t - 1) * (t - 1) * ((0.5 + 1) * (t - 1) + 0.5) + 1;
export const ANTICIPATION_REEL_ROLLING_FORMULA = (t: number): number => -0.6884 + 2.19771 * t;
export const ANTICIPATION_REEL_ENDING_FORMULA = (t: number): number => 0.5 + 0.5 * t;

// TINT CONTAINER SETTINGS
export const TINT_CONTAINER_WIDTH = SLOTS_CONTAINER_WIDTH;
export const TINT_CONTAINER_HEIGHT = SLOTS_CONTAINER_HEIGHT;
export const TINT_BACKGROUND_COLOR = 0x000000;
export const TINT_ALPHA = 0.2;

// PAY_TABLE SETTINGS
export const PAY_TABLE_BACKGROUND_COLOR = 0x000000;
export const PAY_TABLE_NUMBER_COLOR = 0xffffff;
export const PAY_TABLE_TEXT_COLOR = 0xf6e87e;

// FREE SPINS
export const FREE_SPINS_FADE_DURATION = 2000;
export const FREE_SPINS_TIME_OUT_BANNER = 1000;

// WIN STAGES
export enum WinStages {
  None,
  BaseWin,
  BigWin,
  MegaWin,
  GreatWin,
  EpicWin,
}

// LINES SETTINGS
type Position = {
  [key: number]: {
    x: number;
    y: number;
  };
};
export const POSITION: Position = {
  0: {
    x: REEL_WIDTH / 2,
    y: SLOT_HEIGHT / 2,
  },
  1: {
    x: REEL_WIDTH + REEL_WIDTH / 2,
    y: SLOT_HEIGHT / 2,
  },
  2: {
    x: REEL_WIDTH * 2 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT / 2,
  },
  3: {
    x: REEL_WIDTH * 3 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT / 2,
  },
  4: {
    x: REEL_WIDTH * 4 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT / 2,
  },
  5: {
    x: REEL_WIDTH / 2,
    y: SLOT_HEIGHT + SLOT_HEIGHT / 2,
  },
  6: {
    x: REEL_WIDTH + REEL_WIDTH / 2,
    y: SLOT_HEIGHT + SLOT_HEIGHT / 2,
  },
  7: {
    x: REEL_WIDTH * 2 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT + SLOT_HEIGHT / 2,
  },
  8: {
    x: REEL_WIDTH * 3 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT + SLOT_HEIGHT / 2,
  },
  9: {
    x: REEL_WIDTH * 4 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT + SLOT_HEIGHT / 2,
  },
  10: {
    x: REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 2 + SLOT_HEIGHT / 2,
  },
  11: {
    x: REEL_WIDTH + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 2 + SLOT_HEIGHT / 2,
  },
  12: {
    x: REEL_WIDTH * 2 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 2 + SLOT_HEIGHT / 2,
  },
  13: {
    x: REEL_WIDTH * 3 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 2 + SLOT_HEIGHT / 2,
  },
  14: {
    x: REEL_WIDTH * 4 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 2 + SLOT_HEIGHT / 2,
  },
};

// LINE NUMBERS CONTAINER SETTINGS
export const LINES_NUMBER_CONTAINER_HEIGHT = SLOTS_CONTAINER_HEIGHT;
export const LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X = 5;
export const RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X = 0;
export const LEFT_LINES_NUMBERS_CONTAINER_POSITIONS = [
  { id: 0, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 0 },
  { id: 1, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 35 },
  { id: 2, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 70 },
  { id: 3, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 105 },
  { id: 4, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 140 },
  { id: 5, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 175 },
  { id: 6, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 210 },
  { id: 7, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 245 },
  { id: 8, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 280 },
  { id: 9, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 315 },
  { id: 10, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 350 },
  { id: 11, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 385 },
];
export const RIGHT_LINES_NUMBERS_CONTAINER = [
  { id: 12, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 0 },
  { id: 13, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 32 },
  { id: 14, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 64 },
  { id: 15, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 96 },
  { id: 16, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 128 },
  { id: 17, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 160 },
  { id: 18, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 192 },
  { id: 19, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 224 },
  { id: 20, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 256 },
  { id: 21, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 288 },
  { id: 22, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 320 },
  { id: 23, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 352 },
  { id: 24, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 384 },
];

// BUY FEATURE BTN
export const FEATURE_BTN_CONTAINER_POSITION_Y = -75;
export const FEATURE_BTN_CONTAINER_POSITION_X = -845;
export const FEATURE_BTN_WIDTH = 140;
export const FEATURE_BTN_HEIGHT = 165;
export const FEATURE_BTN_WIDTH_MOBILE = 180;
export const FEATURE_BTN_HEIGHT_MOBILE = 79.5;

// BUY FEATURE POPUP
export const FEATURE_POPUP_WIDTH = 1004;
export const FEATURE_POPUP_HEIGHT = 813;
export const FEATURE_POPUP_POSITION_X = -500;
export const FEATURE_POPUP_POSITION_Y = -450;
export const FEATURE_POPUP_TITLE_POSITION_X = FEATURE_POPUP_WIDTH / 2;
export const FEATURE_POPUP_TITLE_POSITION_Y = 120;
export const FEATURE_POPUP_TOTAL_CONST_TEXT_POSITION_X = FEATURE_POPUP_TITLE_POSITION_X;
export const FEATURE_POPUP_TOTAL_CONST_TEXT_POSITION_Y = 210;
export const FEATURE_POPUP_TOTAL_CONST_TEXT_AMOUNT_POSITION_X = FEATURE_POPUP_TITLE_POSITION_X;
export const FEATURE_POPUP_TOTAL_CONST_TEXT_AMOUNT_POSITION_Y = 250;
export const FEATURE_POPUP_BET_TEXT_POSITION_X = FEATURE_POPUP_TITLE_POSITION_X;
export const FEATURE_POPUP_BET_TEXT_POSITION_Y = 375;
export const FEATURE_POPUP_MINUS_BTN_WIDTH = 73;
export const FEATURE_POPUP_MINUS_BTN_HEIGHT = 83;
export const FEATURE_POPUP_MINUS_BTN_POSITION_Y = 455;
export const FEATURE_POPUP_MINUS_BTN_POSITION_X = 240;
export const FEATURE_POPUP_PLUS_BTN_WIDTH = FEATURE_POPUP_MINUS_BTN_WIDTH;
export const FEATURE_POPUP_PLUS_BTN_HEIGHT = FEATURE_POPUP_MINUS_BTN_HEIGHT;
export const FEATURE_POPUP_PLUS_BTN_POSITION_Y = FEATURE_POPUP_MINUS_BTN_POSITION_Y;
export const FEATURE_POPUP_PLUS_BTN_POSITION_X = 697;
export const FEATURE_POPUP_INPUT_WIDTH = 345;
export const FEATURE_POPUP_INPUT_HEIGHT = 84;
export const FEATURE_POPUP_INPUT_POSITION_Y = 453;
export const FEATURE_POPUP_INPUT_POSITION_X = 333;
export const FEATURE_POPUP_BET_VALUE_POSITION_Y = 460;
export const FEATURE_POPUP_BET_VALUE_POSITION_X = FEATURE_POPUP_TITLE_POSITION_X;
export const FEATURE_POPUP_CANCEL_BTN_WIDTH = 226;
export const FEATURE_POPUP_CANCEL_BTN_HEIGHT = 145;
export const FEATURE_POPUP_CANCEL_BTN_POSITION_X = 253;
export const FEATURE_POPUP_CANCEL_BTN_POSITION_Y = 538;
export const FEATURE_POPUP_OK_BTN_WIDTH = FEATURE_POPUP_CANCEL_BTN_WIDTH;
export const FEATURE_POPUP_OK_BTN_HEIGHT = FEATURE_POPUP_CANCEL_BTN_HEIGHT;
export const FEATURE_POPUP_OK_BTN_POSITION_X = 539;
export const FEATURE_POPUP_OK_BTN_POSITION_Y = FEATURE_POPUP_CANCEL_BTN_POSITION_Y;

// BUY FEATURE CONFIRM
export const FEATURE_POPUP_CONFIRM_WIDTH = FEATURE_POPUP_WIDTH;
export const FEATURE_POPUP_CONFIRM_HEIGHT = FEATURE_POPUP_HEIGHT;
export const FEATURE_POPUP_CONFIRM_POSITION_Y = FEATURE_POPUP_POSITION_Y;
export const FEATURE_POPUP_CONFIRM_POSITION_X = FEATURE_POPUP_POSITION_X;
export const FEATURE_POPUP_CONFIRM_TITLE_POSITION_Y = 120;
export const FEATURE_POPUP_CONFIRM_TITLE_POSITION_X = FEATURE_POPUP_CONFIRM_WIDTH / 2;
export const FEATURE_POPUP_CONFIRM_TOTAL_COST_TEXT_POSITION_X = FEATURE_POPUP_CONFIRM_TITLE_POSITION_X;
export const FEATURE_POPUP_CONFIRM_TOTAL_COST_TEXT_POSITION_Y = 350;
export const FEATURE_POPUP_CONFIRM_TOTAL_COST_VALUE_POSITION_X = FEATURE_POPUP_CONFIRM_TITLE_POSITION_X;
export const FEATURE_POPUP_CONFIRM_TOTAL_COST_VALUE_POSITION_Y = 420;
export const FEATURE_POPUP_CONFIRM_CANCEL_BTN_WIDTH = FEATURE_POPUP_CANCEL_BTN_WIDTH;
export const FEATURE_POPUP_CONFIRM_CANCEL_BTN_HEIGHT = FEATURE_POPUP_CANCEL_BTN_HEIGHT;
export const FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION_Y = FEATURE_POPUP_CANCEL_BTN_POSITION_Y;
export const FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION_X = FEATURE_POPUP_CANCEL_BTN_POSITION_X;
export const FEATURE_POPUP_CONFIRM_OK_BTN_WIDTH = FEATURE_POPUP_CANCEL_BTN_WIDTH;
export const FEATURE_POPUP_CONFIRM_OK_BTN_HEIGHT = FEATURE_POPUP_CANCEL_BTN_HEIGHT;
export const FEATURE_POPUP_CONFIRM_OK_BTN_POSITION_Y = FEATURE_POPUP_CANCEL_BTN_POSITION_Y;
export const FEATURE_POPUP_CONFIRM_OK_BTN_POSITION_X = FEATURE_POPUP_OK_BTN_POSITION_X;

// FRAME
export const REELS_FRAME_POSITION_X = -206;
export const REELS_FRAME_POSITION_Y = 0;
export const REELS_FRAME_WIDTH = GAME_CONTAINER_WIDTH + 410;
export const REELS_FRAME_HEIGHT = GAME_CONTAINER_HEIGHT + 284;

// PICKEM
export const PICKEM_FRAME_POSITION_X = -220;
export const PICKEM_FRAME_POSITION_Y = 0;
export const PICKEM_FRAME_WIDTH = GAME_CONTAINER_WIDTH + 440;
export const PICKEM_FRAME_HEIGHT = GAME_CONTAINER_HEIGHT + 280;

export const PICKEM_CONTAINER_X = 250;
export const PICKEM_CONTAINER_Y = 115;

export const PICKEM_AWARD_WIDTH = 150;
export const PICKEM_AWARD_HEIGHT = 220;

// STICKY WILD FEATURE
export const WILD_SCALE = 1;
