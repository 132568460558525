import type React from 'react';

const StartSvg = () => {
  return (
    <svg width="272" height="113" viewBox="0 0 272 113" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="10" y="10.8838" width="252" height="92" rx="38" fill="#FFCC48" stroke="#060404" strokeWidth="4" />
      <path
        d="M167 58.6158L168 60.3479C170.667 58.8083 170.667 54.9593 168 53.4197L123 27.4389C120.333 25.8993 117 27.8238 117 30.903L117 82.8645C117 85.9437 120.333 87.8683 123 86.3287L168 60.3479L167 58.6158Z"
        fill="white"
        stroke="#060404"
        strokeWidth="4"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default StartSvg;
