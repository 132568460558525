export interface PopupProps {
  isExpired: boolean;
}

export enum PopupTypes {
  BUY_FEATURE,
  BUY_FEATURE_CONFIRMATION,
  FREE_SPINS,
  FREE_SPINS_END,
  FREE_ROUNDS,
  FREE_ROUNDS_END,
}
