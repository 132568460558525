import type { Application } from 'pixi.js';

import { EventTypes } from '../../global.d';
import { eventManager } from '../config';

import type Animation from './animation';
import Tween from './tween';

const lerp = (startValue: number, endValue: number, phase: number): number => {
  return startValue * (1 - phase) + endValue * phase;
};
class Animator {
  private application: Application;

  public tweenAnimations: Tween[] = [];

  constructor(application: Application) {
    this.application = application;

    eventManager.on(EventTypes.REGISTER_ANIMATOR, this.registerAnimator.bind(this));
    eventManager.on(EventTypes.START_TWEEN_ANIMATION, (animation: Animation) => {
      this.startAnimation(animation);
    });
    eventManager.on(EventTypes.REMOVE_TWEEN_ANIMATION, (animation: Tween) => {
      this.removeTween(animation);
    });
    eventManager.on(EventTypes.END_TWEEN_ANIMATION, (animation: Tween) => {
      this.endAnimation(animation);
    });

    eventManager.emit(EventTypes.REGISTER_ANIMATOR, this.processTween.bind(this));
  }

  private registerAnimator(animator: () => void) {
    this.application.ticker.add(animator);
  }

  private endAnimation(animation: Tween): void {
    animation.object[animation.property] = animation.target;
    this.removeTween(animation);
  }

  private startAnimation(animation: Animation): void {
    if (animation instanceof Tween) this.addTween(animation);
  }

  private removeTween(tween: Tween) {
    if (this.tweenAnimations.indexOf(tween) !== -1) this.tweenAnimations.splice(this.tweenAnimations.indexOf(tween), 1);
  }

  public addTween(tween: Tween): void {
    this.tweenAnimations.push(tween);
  }

  private getPhase(startTime: number, duration: number): number {
    if (duration <= 0) return 1;
    return Math.min(1, Math.max((Date.now() - startTime) / duration, 0));
  }

  private processTween(_delta: number): void {
    if (this.tweenAnimations.length) {
      for (let i = 0; i < this.tweenAnimations.length; i++) {
        const tweenAnimation = this.tweenAnimations[i];
        const phase = this.getPhase(tweenAnimation!.startTime, tweenAnimation!.duration);
        if (tweenAnimation!.update !== null) {
          tweenAnimation!.update(
            lerp(tweenAnimation!.propertyBeginValue, tweenAnimation!.target, tweenAnimation!.easing(phase)),
          );
        } else {
          tweenAnimation!.object[tweenAnimation!.property] = lerp(
            tweenAnimation!.propertyBeginValue,
            tweenAnimation!.target,
            tweenAnimation!.easing(phase),
          );
        }

        tweenAnimation!.onChange();
        if (phase === 1) {
          tweenAnimation!.object[tweenAnimation!.property] = tweenAnimation!.target;
          // eslint-disable-next-line no-plusplus
          this.tweenAnimations.splice(i--, 1)[0]!.onComplete();
        }
      }
    }
  }
}

export default Animator;
