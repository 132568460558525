import { isMobile } from 'pixi.js';

import { ISongs } from '../config';
import { GameMode, ISettledBet, PreLoadedGrantBreakdown } from '../global.d';
import {
  setGameMode,
  setIsDuringBigWinLoop,
  setSlotConfig,
} from '../gql/cache';
import type { IConfig } from '../gql/d';
import type SpineAnimation from '../slotMachine/animations/spine';
import type { Icon } from '../slotMachine/d';
import type { TPlay } from '@phoenix7dev/audio-api/dist/d';

declare namespace Helper {
  export type RestArguments = unknown[];
  export type Callback<T> = (...args: RestArguments) => T;
  export interface WrapArguments<T> {
    (fn: Callback<T>, ...partOne: RestArguments): Callback<T>;
  }
}

export const getWsUtl = (url: string): string => {
  const { protocol, host } = window.location;
  return `${protocol.replace('http', 'ws')}//${host}${url}`;
};

export const parseQuery = <T>(): T => {
  const { search } = window.location;
  const str = search
    .slice(1)
    .split('&')
    .map((i) => i.split('='));

  const param = str.reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key as string]: value,
    };
  }, {});
  return param as T;
};

export const wrap =
  (fn: CallableFunction, ...partOne: Helper.RestArguments) =>
    (...partTwo: Helper.RestArguments): unknown => {
      const args: Helper.RestArguments = [...partOne, ...partTwo];
      if (args.length) {
        return fn(...args);
      }
      return fn();
    };

export const isMobileDevice = (): boolean => {
  const regex =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|WPDesktop/;
  return (
    regex.test(window.navigator.userAgent) ||
    (window.navigator.platform === 'MacIntel' &&
      typeof (window.navigator as unknown as { standalone: string }).standalone !== 'undefined')
  );
};

export const getUserConfig = (name: string, value: boolean): boolean => {
  const item = localStorage.getItem('config');
  if (item) {
    const config = JSON.parse(item) as IConfig;
    if (config[name as string] === undefined) {
      Object.assign(config, { [name]: value });
      localStorage.setItem('config', JSON.stringify(config));
    }
    return config[name as string] as boolean;
  }
  localStorage.setItem('config', JSON.stringify({ [name]: value }));
  return value;
};

export const setUserConfig = (name: string, value: boolean): boolean => {
  const item = localStorage.getItem('config');
  if (item) {
    const config = JSON.parse(item) as IConfig;
    Object.assign(config, { [name]: value });
    localStorage.setItem('config', JSON.stringify(config));
  }
  return value;
};

export const isFreeSpinMode = (mode: GameMode): boolean => {
  return mode === GameMode.FREE_SPINS;
};

export const isPickemMode = (mode: GameMode): boolean => {
  return mode === GameMode.PICKEM;
};

export const isRegularMode = (mode: GameMode): boolean => {
  return mode === GameMode.REGULAR || mode === GameMode.FREE_ROUND_BONUS;
};

export const isFreeRoundBonusMode = (mode: GameMode): boolean => {
  return mode === GameMode.FREE_ROUND_BONUS;
};
export const countCoins = (bet: {
  totalAmount?: number;
  coinAmount?: number;
  coinValue?: number;
  lines?: number;
}): number => {
  if (bet.totalAmount) {
    return (bet.totalAmount * (bet.coinValue || 100)) / 100;
  }
  return (
    ((bet.coinAmount || 0) *
      (bet.coinValue || 100) *
      (bet.lines || setSlotConfig().lines.length)) /
    100
  );
};

export const getIconById = (icons: Icon[], id: string): Icon => {
  const result = icons.find((icon) => icon.id === id);
  if (result) {
    return result;
  }
  throw new Error(`NO SUCH ICON FOR ID ${id}`);
};

export const destroySpine = (spine: SpineAnimation): void => {
  setImmediate(() => {
    if (spine.getSpine() && spine.getSpine().skeleton) {
      spine.getSpine().destroy();
    }
  });
};

export const calcPercentage = (
  initialValue: number,
  percent: number,
): number => {
  return (initialValue / 100) * percent;
};

export const canPressSpin = ({
  gameMode,
  isFreeSpinsWin,
  isSpinInProgress,
  isSlotBusy,
  isSlotStopped,
  isBuyFeaturePopupOpened,
  isPickem,
  isAutoPlay,
  brokenBuyFeature,
  isPopupOpened,
  transitionStarted,
}: {
  gameMode: GameMode;
  isFreeSpinsWin: boolean;
  isSpinInProgress: boolean;
  isSlotBusy: boolean;
  isSlotStopped: boolean;
  isBuyFeaturePopupOpened: boolean;
  isPickem?: boolean;
  isAutoPlay?: boolean;
  brokenBuyFeature?: boolean;
  isPopupOpened?: boolean;
  transitionStarted?: boolean;
}): boolean => {
  if (transitionStarted) {
    return false;
  }

  if (isPickem) {
    return false;
  }

  if (
    (gameMode === GameMode.REGULAR || gameMode === GameMode.BUY_FEATURE || gameMode === GameMode.FREE_ROUND_BONUS) &&
    isFreeSpinsWin
  ) {
    return false;
  }

  if (
    isFreeSpinMode(gameMode) && !isSlotBusy
  ) {
    return false;
  }

  if (isSpinInProgress && isSlotStopped) {
    return false;
  }

  if (isPopupOpened) {
    return false;
  }

  if (isBuyFeaturePopupOpened) {
    return false;
  }

  if (isAutoPlay) {
    return false;
  }

  if (brokenBuyFeature) {
    return false;
  }

  return true;
};

export const getPlayListOnBrokenFreeSpins = (
  isDuringBigWinLoop: boolean,
): TPlay[] => {
  if (isDuringBigWinLoop) {
    return [
      { type: ISongs.FreeSpinBGM_Loop, volume: 0 },
      { type: ISongs.BigWin_Loop },
    ];
  }
  return [{ type: ISongs.FreeSpinBGM_Loop }];
};

export const getSoundToPlay = (): TPlay[] => {
  if (isPickemMode(setGameMode())) {
    return [{ type: ISongs.PickemBonusBGM_Loop }];
  }

  if (isFreeSpinMode(setGameMode())) {
    return getPlayListOnBrokenFreeSpins(setIsDuringBigWinLoop());
  }

  return [
    { type: ISongs.BaseGameBGM_Base },
    { type: ISongs.BaseGameBGM_Melo, volume: 0 },
  ];
};

export const isTabletPortrait = (_deviceWidth: number, _deviceHeight: number): boolean => {
  const isLandscape = _deviceWidth >= _deviceHeight;
  return isMobile.any && !isLandscape && _deviceWidth >= 768 && _deviceWidth < 1000;
};
export const isTabletLandscape = (_deviceWidth: number, _deviceHeight: number): boolean => {
  const isLandscape = _deviceWidth >= _deviceHeight;
  return isMobile.any && isLandscape && _deviceWidth >= 950 && _deviceHeight < 1200;
};
export const isMobilePortrait = (_deviceWidth: number, _deviceHeight: number): boolean => {
  const isLandscape = _deviceWidth >= _deviceHeight;
  return isMobile.any && !isLandscape && _deviceWidth < 768;
};
export const isMobileLandscape = (_deviceWidth: number, _deviceHeight: number): boolean => {
  const isLandscape = _deviceWidth >= _deviceHeight;
  return isMobile.any && isLandscape && _deviceWidth < 950;
};

export const checkPhoenixAnticipation = (placeBet: ISettledBet): boolean => {
  const showPhoenixAnticipation = shouldDisplayPhoenixAnticipation();
  const grants = placeBet.bet.data.features.bonusData?.preLoadedGrantBreakdown;
  let freeSpinsGranted = 0;
  if (grants) {
    freeSpinsGranted = getFreeSpinGrantedCount(placeBet.bet.data.features.bonusData?.preLoadedGrantBreakdown)
  }
  if (freeSpinsGranted >= 16 && showPhoenixAnticipation) {
    return true;
  }
  return false;
};

export const shouldDisplayPhoenixAnticipation = (): boolean => {
  const num = Math.random();

  if (num <= 0.5) {
    return true;
  } else {
    return false;
  }
};

export const getFreeSpinGrantedCount = (grants: PreLoadedGrantBreakdown[]): number =>
  grants.reduce((acc, item) => {
    const count = item!.grants[0]!.grants[0]?.count!;
    return acc += count;
  }, 0);
