import { Colors } from '../../config/variables';
import { EventTypes, PopupOpeningTypes } from '../../global.d';
import {
  setGameMode,
  setIsBuyFeaturePopupOpened,
  setIsContinueAutoSpinsAfterFeature,
  setIsOpenAutoplayPopup,
  setIsOpenBetSettingsPopup,
  setIsOpenHistoryPopup,
  setIsOpenInfoPopup,
  setIsPopupOpeningInProgress,
  setIsTransitionStarted,
} from '../../gql/cache';
import { calcPercentage, isFreeRoundBonusMode, isPickemMode, isRegularMode } from '../../utils';
import { eventManager } from '../config';
import { UiButton } from '../ui/uiButton';

class BetBtn extends UiButton {
  private isAutoSpins = false;

  private isSpinInProgress = false;

  private isSlotBusy = false;

  private isFreeSpinsWin = false;

  private isFreeRoundBonus = false;

  constructor() {
    super('bet');
    this.interactive = !this.isDisabled;
    this.btn.tint = Colors.GAME_COLOR;
    this.isFreeRoundBonus = isFreeRoundBonusMode(setGameMode());
    this.on('click', () => this.handleClick());
    this.on('touchstart', () => this.handleClick());
    this.initSubscriptions();
    this.handleDisable();
    this.handleHide();
  }

  public override onModeChange(): void {
    this.isFreeRoundBonus = isFreeRoundBonusMode(setGameMode());
    this.handleDisable();
  }

  private initSubscriptions = (): void => {
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.CLOSE_BUY_FEATURE_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_AUTO_SPINS, (isAutoSpins: boolean) => {
      this.isAutoSpins = isAutoSpins;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SPIN_IN_PROGRESS, (isSpinInProgress: boolean) => {
      this.isSpinInProgress = isSpinInProgress;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SLOT_IS_BUSY, (isSlotBusy: boolean) => {
      this.isSlotBusy = isSlotBusy;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_FREESPINS_WIN, (isFreeSpinWin: boolean) => {
      this.isFreeSpinsWin = isFreeSpinWin;
      this.handleDisable();
    });
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.CLOSE_BUY_FEATURE_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.START_MODE_CHANGE_FADE, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.END_MODE_CHANGE_FADE, () => {
      this.handleDisable();
    });
  };

  private handleDisable = (): void => {
    this.setDisable(
      !isRegularMode(this.getGameMode) ||
        this.isFreeRoundBonus ||
        this.isAutoSpins ||
        this.isSpinInProgress ||
        this.isSlotBusy ||
        this.isFreeSpinsWin ||
        setIsContinueAutoSpinsAfterFeature() ||
        setIsTransitionStarted() ||
        setIsBuyFeaturePopupOpened(),
    );
  };

  public override handleHide(): void {
    if (isPickemMode(this.getGameMode)) {
      this.visible = false;
    } else {
      this.visible = true;
      this.handleDisable();
    }
  }

  private handleClick = (): void => {
    if (!this.isDisabled && setIsPopupOpeningInProgress() === PopupOpeningTypes.NONE) {
      setIsPopupOpeningInProgress(PopupOpeningTypes.BET_SETTINGS_POPUP);

      setIsOpenBetSettingsPopup(!setIsOpenBetSettingsPopup());
      setIsOpenAutoplayPopup(false);
      setIsOpenInfoPopup(false);
      setIsOpenHistoryPopup(false);
      eventManager.emit(EventTypes.DISABLE_ALL_MINI_PAY_TABLES);

      setTimeout(() => setIsPopupOpeningInProgress(PopupOpeningTypes.NONE), 100);
    }
  };

  public override handlePosition(): void {
    this.btn.anchor.set(1, 1);
    let size = calcPercentage(this.applicationSize.width, 7.5);
    const gap = calcPercentage(this.applicationSize.width, 1.25);
    let x = this.applicationSize.width - gap;
    let y = this.applicationSize.height - gap;
    this.setSize(size);

    if (this.isPortraitMode) {
      size = calcPercentage(this.applicationSize.height, 7.5);
      x = this.applicationSize.width - gap;
      y = this.applicationSize.height - gap;

      this.setSize(size);
    }

    this.x = x;
    this.y = y;
  }
}

export default BetBtn;
