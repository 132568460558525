import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { IAuthInput } from '../../global';
import { configGql, getProgressGql } from '../../gql';
import type { IConfig } from '../../gql/d';
import { parseQuery } from '../../utils';
import GameScreen from '../GameScreen';
import LoadScreen from '../LoadScreen';

const App: React.FC = () => {
  const { data } = useQuery<IConfig>(configGql);
  const { data: dataProgress } = useQuery<{
    progress: {
      status: number;
      wasLoaded?: boolean;
    };
  }>(getProgressGql);
  const { i18n } = useTranslation();
  const { progress } = dataProgress!;

  useEffect(() => {
    const { lng } = parseQuery<IAuthInput>();
    if (lng) {
      i18n.changeLanguage(lng);
    }
  }, [i18n]);
  return data?.isAuthorized && progress?.status >= 100 ? <GameScreen /> : <LoadScreen />;
};

export default App;
