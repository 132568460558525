import { isMobile } from 'mobile-device-detect';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import {
  BetBonusReward,
  EventTypes,
  GameMode,
  ISettledBet,
  MessageBannerTypes,
  PreLoadedGrantBreakdown,
} from '../../global.d';
import {
  setCurrentBonus,
  setCurrentBonusId,
  setIsPickemShotInProgress,
  setIsShowSoundToast,
  setIsSoundLoading,
  setSlotConfig,
  setUserLastBetResult,
} from '../../gql';
import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import { getSoundToPlay, updateTextScale } from '../../utils';
import type { IEntryAnimation } from '../animations/d';
import SpineAnimation from '../animations/spine';
import { totalCostTextStyle } from '../buyFeature/textStyles';
import {
  PICKEM_AWARD_HEIGHT,
  PICKEM_AWARD_WIDTH,
  PICKEM_CONTAINER_X,
  PICKEM_CONTAINER_Y,
  PICKEM_FRAME_HEIGHT,
  PICKEM_FRAME_POSITION_X,
  PICKEM_FRAME_POSITION_Y,
  PICKEM_FRAME_WIDTH,
  REELS_AMOUNT,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
  eventManager,
} from '../config';
import { btnStyle, subtitleStyle, titleStyle } from '../messageBanner/textStyles';

import Pickem from './pickem';
import { pickemTitle, pickemValue } from './pickemConfig';

class PickemContainer extends PIXI.Container {
  private animation: SpineAnimation | null = null;

  public frame: PIXI.Sprite;

  public patronsContainer = new PIXI.Container();

  private titleText: PIXI.Text;

  private titleValue: PIXI.Text;

  private round: number;

  private rounds: number;

  private freeSpinsValue: number;

  private grants: PreLoadedGrantBreakdown[];

  private isClicked: boolean;

  constructor(grants: PreLoadedGrantBreakdown[]) {
    super();
    this.width = SLOTS_CONTAINER_WIDTH;
    this.height = SLOTS_CONTAINER_HEIGHT;
    this.x = -SLOTS_CONTAINER_WIDTH / 2;
    this.y = -SLOTS_CONTAINER_HEIGHT * 0.7;
    this.sortableChildren = true;
    this.isClicked = false;
    this.round = 0;
    this.rounds = grants.length;
    this.grants = grants;
    this.freeSpinsValue = 0;
    this.frame = this.initReelsFrame();
    this.titleText = this.initTitleText();
    this.titleValue = this.initTitleValue(`${this.freeSpinsValue}`);
    this.addChild(this.frame);
    this.addChild(this.titleText);
    this.addChild(this.titleValue);
    this.createContainers();
    this.initAnimation();
    this.addChild(this.patronsContainer);
    this.initPatrons();
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.on(EventTypes.PICKEM_SHOT_RESOLVED, (placeBet: ISettledBet) => {
      setCurrentBonus({
        ...placeBet!.bet.data.bonuses[0]!,
        isActive: true,
        currentRound: 0,
        rounds: this.freeSpinsValue,
      });
      const reward = placeBet.rewards[1] as BetBonusReward;
      setCurrentBonusId(reward.userBonusId);
      if (reward.userBonus?.bonus?.reelSetId) {
        setUserLastBetResult({ ...setUserLastBetResult(), reelSetId: reward.userBonus.bonus.reelSetId });
      }
      this.createFinishPopup(this.freeSpinsValue);
    });
  }

  private initReelsFrame(): PIXI.Sprite {
    const frame = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.pickemFrame));
    frame.height = PICKEM_FRAME_HEIGHT;
    frame.width = PICKEM_FRAME_WIDTH;
    frame.y = PICKEM_FRAME_POSITION_Y;
    frame.x = PICKEM_FRAME_POSITION_X;
    return frame;
  }

  private initPatrons(): void {
    this.patronsContainer.x = 400;
    this.patronsContainer.y = 900;

    for (let i = 0; i < this.rounds; i++) {
      const patron = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.patron));
      patron.y = 0;
      patron.x = patron.width * i;
      this.patronsContainer.addChild(patron);
    }
  }

  private initTitleText(): PIXI.Text {
    const text = new PIXI.Text(i18n.t('freeSpinsTitle') as string, pickemTitle);
    updateTextScale(text, isMobile ? 270 : 260, isMobile ? 200 : 250);
    text.y = 50;
    text.x = SLOTS_CONTAINER_WIDTH / 2;
    text.anchor.set(0.5, 0.5);

    return text;
  }

  private initTitleValue(freeSpinsValue: string): PIXI.Text {
    const text = new PIXI.Text(freeSpinsValue, pickemValue);
    text.y = 60;
    text.x = SLOTS_CONTAINER_WIDTH / 2;
    text.anchor.set(0.5, 0);

    return text;
  }

  private createContainers(): void {
    const container = new PIXI.Container();
    container.y = PICKEM_CONTAINER_Y;
    container.x = PICKEM_CONTAINER_X;
    for (let row = 0; row < SLOTS_PER_REEL_AMOUNT; row++) {
      for (let col = 0; col < REELS_AMOUNT; col++) {
        const pickem = new Pickem(row * REELS_AMOUNT + col);
        pickem.x = col * PICKEM_AWARD_WIDTH;
        pickem.y = PICKEM_AWARD_HEIGHT * row;
        pickem.zIndex = 1;
        pickem.interactive = true;
        pickem.on('pointerdown', (): void => {
          if (this.round < this.rounds && pickem.awardSymbol.visible && !this.isClicked) {
            this.isClicked = true;
            const count = this.grants[this.round]!.grants[0]!.grants[0]?.count!;
            this.freeSpinsValue += pickem.handlePickem(count);
            setTimeout(() => {
              this.titleValue.text = `${this.freeSpinsValue}`;
              this.isClicked = false;
            }, 1000);
            AudioApi.play({ type: ISongs.PickemBonusShot, stopPrev: true });
            this.startAnimation(col);
            this.patronsContainer.removeChildAt(0);
            this.round += 1;

            if (this.round === this.grants.length) {
              eventManager.emit(EventTypes.PICKEM_SHOT);
              setIsPickemShotInProgress(true);
            }

            if (AudioApi.isRestricted) {
              const soundToPlay = getSoundToPlay();
              AudioApi.unSuspend();
              AudioApi.processRestriction({
                restricted: false,
                listToPlay: soundToPlay,
                beforeSetQueue: () => setIsSoundLoading(true),
                onChangeRestriction: () => setIsShowSoundToast(false),
              });
            }
          }
        });
        container.addChild(pickem);
        this.addChild(container);
      }
    }
  }

  initAnimation(): void {
    this.animation = new SpineAnimation({}, PIXI.Loader.shared.resources['pickem_bonus']!.spineData!);
    this.animation.spine.x = 630;
    this.animation.spine.y = 550;
    this.animation!.setAnimation('all_hand', false);
    this.animation!.spine.state.addListener({
      complete: (entry: IEntryAnimation) => {
        if (entry.animation.name === 'right_hand_fire' || entry.animation.name === 'left_hand_fire') {
          this.animation!.spine.state.setAnimation(0, 'all_hand', false);
        }
      },
    });
    this.addChild(this.animation!.spine);
  }

  private startAnimation(id: number): void {
    const anim = id < 2 ? 'right_hand_fire' : 'left_hand_fire';

    this.animation!.setAnimation(anim, false);
    this.animation!.start();
    this.animation!.complete = [];
  }

  private createFinishPopup(freeSpinsValue: number): void {
    // Used FreeSpinEntrancePopup as PickemBonusExitPopup cause there is no Free Spins entrance popup and no sound for PickemBonusExitPopup
    AudioApi.playAsync({
      type: ISongs.FreeSpinEntrancePopup,
      fadeOutImmediately: [ISongs.BaseGameBGM_Melo, ISongs.BaseGameBGM_Base, ISongs.PickemBonusBGM_Loop],
    }).then(() => {
      if (!AudioApi.isPlaying(ISongs.FreeSpinBGM_Loop)) {
        AudioApi.fadeIn(0, ISongs.PickemBonusBGM_Loop);
      }
    });
    eventManager.emit(EventTypes.CREATE_MESSAGE_BANNER, {
      bannerBg: 'free_spins',
      type: MessageBannerTypes.Freespin,
      title: {
        text: 'pickemMessageBannerText1',
        styles: titleStyle,
      },
      subtitle: {
        text: i18n.t('pickemWinMessageBannerText2', { freeSpinsValue }),
        styles: totalCostTextStyle,
      },
      value: {
        text: i18n.t('pickemWinMessageBannerText1', { freeSpinsValue }),
        styles: subtitleStyle,
      },
      btn: {
        text: 'pickemMessageBannerBtnText',
        styles: btnStyle,
      },
      callback: () => {
        let reelSet = setUserLastBetResult().id
          ? setSlotConfig().reels.find((reelSet) => reelSet.id === setUserLastBetResult().reelSetId)!
          : setSlotConfig().reels.find((reelSet) => {
              return (reelSet.type as unknown as string) === 'DEFAULT';
            })!;
        if (!reelSet.layout.length) {
          reelSet = setSlotConfig().reels.find((reelSet) => (reelSet.type as unknown as string) === 'DEFAULT')!;
        }
        eventManager.emit(EventTypes.START_MODE_CHANGE_FADE, {
          mode: GameMode.FREE_SPINS,
          reelPositions: [0, 0, 0, 0, 0],
          reelSetId: reelSet.id,
          callback: () => {
            this.handleDestroy();
          },
        });
        if (AudioApi.isPlaying(ISongs.FreeSpinEntrancePopup)) {
          AudioApi.fadeOut(1000, ISongs.FreeSpinEntrancePopup);
        } else if (AudioApi.isPlaying(ISongs.PickemBonusBGM_Loop)) {
          AudioApi.fadeOut(1000, ISongs.PickemBonusBGM_Loop);
        }
      },
      preventDefaultDestroy: true,
    });
  }

  public handleDestroy(): void {
    eventManager.off(EventTypes.PICKEM_SHOT_RESOLVED);
    this.destroy({ children: true, texture: false, baseTexture: false });
  }

  private resize(_width: number, _height: number): void {
    //
  }
}

export default PickemContainer;
