import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs, config } from '../../config';
import { EventTypes, GameMode, ISettledBet, IUserBalance } from '../../global.d';
import {
  configGql,
  getAutoSpinsGql,
  getBetAmountGql,
  getGameModeGql,
  getProgressGql,
  getUserGql,
  isStoppedGql,
  placeBetGql,
  setAutoSpinsAmount,
  setAutoSpinsLeft,
  setAutoSpinsStartBalance,
  setBetAmount,
  setBrokenGame,
  setCoinAmount,
  setCoinValue,
  setCurrency,
  setCurrentBonus,
  setCurrentBonusId,
  setGameHistory,
  setGameMode,
  setIsAutoSpins,
  setIsBuyFeaturePopupOpened,
  setIsContinueAutoSpinsAfterFeature,
  setIsFreeSpinsWin,
  setIsShowSoundToast,
  setIsSlotBusy,
  setIsSoundLoading,
  setIsSpinInProgress,
  setIsStopOnAnyWin,
  setIsStopOnBalanceDecrease,
  setIsStopOnBalanceIncrease,
  setIsStopOnFeatureWin,
  setIsStopOnWinExceeds,
  setLastRegularWinAmount,
  setSlotConfig,
  setStopOnBalanceDecrease,
  setStopOnBalanceIncrease,
  setStopOnWinExceeds,
  setWinAmount,
  stressfulGql,
} from '../../gql';
import {
  setBrokenBuyFeature,
  setIsBuyFeaturePurchased,
  setIsOpenAutoplayPopup,
  setIsOpenBetSettingsPopup,
  setIsOpenHistoryPopup,
  setIsOpenInfoPopup,
  setIsPickemShotInProgress,
  setIsPopupOpened,
  setIsTransitionStarted,
} from '../../gql/cache';
import type { IConfig, ISlotConfig, IStressful } from '../../gql/d';
import SlotMachine from '../../slotMachine';
import { SlotMachineState, eventManager } from '../../slotMachine/config';
import { canPressSpin, checkPhoenixAnticipation, getSoundToPlay, isFreeSpinMode, showCurrency } from '../../utils';
import { saveReelPosition } from '../../utils/math';

import type { IPlaceBetInput } from './d';

let timeout: ReturnType<typeof setTimeout>;

const Spin: React.FC = () => {
  const { data } = useQuery<IConfig>(configGql);
  const { isTurboSpin } = data!;
  const { data: dataBet } = useQuery<{ betAmount: number }>(getBetAmountGql);
  const { data: stressful } = useQuery<{ stressful: IStressful }>(stressfulGql);
  const { id: slotId, lineSets } = useReactiveVar<ISlotConfig>(setSlotConfig);
  const lineSet = lineSets[0];
  const isFreeSpinsWin = useReactiveVar<boolean>(setIsFreeSpinsWin);
  const { data: userData } = useQuery<{ user: IUserBalance }>(getUserGql);
  const { data: dataProgress } = useQuery<{
    progress: { status: number; wasLoaded?: boolean };
  }>(getProgressGql);
  const { data: dataSlotStopped } = useQuery<{ isSlotStopped: boolean }>(isStoppedGql);

  const { data: gameModeData } = useQuery<{
    gameMode: GameMode;
  }>(getGameModeGql);
  const { gameMode } = gameModeData!;
  const balanceAmount = userData?.user.balance.amount || 0;
  const winThreeTimes = useReactiveVar<boolean[]>(setGameHistory);

  const { progress } = dataProgress!;

  const { data: autoSpins } = useQuery<{
    isAutoSpins: boolean;
    autoSpinsLeft: number;
  }>(getAutoSpinsGql);
  const { isAutoSpins } = autoSpins!;

  const isFreeSpinModeOnTotalWinBannerStep: () => boolean = () =>
    isFreeSpinMode(setGameMode()) &&
    !setCurrentBonus().isActive &&
    setCurrentBonus().rounds === setCurrentBonus().currentRound;

  const [fnGet, { client }] = useMutation<{ placeBet: ISettledBet }, { input: IPlaceBetInput }>(placeBetGql, {
    onError() {
      eventManager.emit('placeBetCompleted');
    },

    async onCompleted({ placeBet }) {
      eventManager.emit('placeBetCompleted');
      client.writeQuery({
        query: getUserGql,
        data: {
          ...userData,
          user: {
            ...userData?.user,
            balance: placeBet.balance.placed,
          },
        },
      });

      if (setIsPickemShotInProgress()) {
        setIsPickemShotInProgress(false);
        eventManager.emit(EventTypes.PICKEM_SHOT_RESOLVED, placeBet);

        return;
      }

      const showPhoenixAnticipation = checkPhoenixAnticipation(placeBet);
      if (showPhoenixAnticipation) {
        eventManager.emit(EventTypes.PHOENIX_ANTICIPATION_START);
        eventManager.once(EventTypes.PHOENIX_ANTICIPATION_END, () => {
          SlotMachine.getInstance().setResult(placeBet);
          if (SlotMachine.getInstance().isStopped) {
            SlotMachine.getInstance().spin(isTurboSpin);
          }
        });
      } else {
        SlotMachine.getInstance().setResult(placeBet);
        if (SlotMachine.getInstance().isStopped) {
          SlotMachine.getInstance().spin(isTurboSpin);
        }
      }

      const callBack = () => {
        const win = placeBet.bet.result.winCoinAmount;
        const lastThreeSpins = [...setGameHistory().slice(1), !!win];
        const thirdWinInRow = _.reduce(lastThreeSpins, (acc, item) => acc && item);
        setGameHistory(lastThreeSpins);
        if (placeBet.bet.coinAmount * setSlotConfig().lineSets[0]!.coinAmountMultiplier * 5 <= win && !thirdWinInRow) {
          AudioApi.fadeIn(1000, ISongs.BaseGameBGM_Melo);
        }
        client.writeQuery({
          query: getUserGql,
          data: {
            ...userData,
            user: {
              ...userData?.user,
              balance: placeBet.balance.settled,
            },
          },
        });
        saveReelPosition(placeBet.bet.result.reelPositions);
      };
      SlotMachine.getInstance().setStopCallback(callBack.bind(this));
    },
  });

  const resetPopupsStateToClosed = () => {
    setIsOpenBetSettingsPopup(false);
    setIsOpenAutoplayPopup(false);
    setIsOpenInfoPopup(false);
    setIsOpenHistoryPopup(false);
  };

  const onSpin = useCallback(
    (isTurboSpin?: boolean) => {
      resetPopupsStateToClosed();
      clearTimeout(timeout);
      setWinAmount(0);
      setLastRegularWinAmount(0);
      const freeRoundBonus = setCurrentBonus().gameMode === GameMode.FREE_ROUND_BONUS && setCurrentBonus().isActive;
      const spinState = SlotMachine.getInstance().state;
      if (
        spinState !== SlotMachineState.IDLE ||
        (!isFreeSpinMode(setGameMode()) && (!setBrokenGame() || setGameMode() === GameMode.FREE_ROUND_BONUS))
      ) {
        SlotMachine.getInstance().spin(isTurboSpin);
      }
      if (spinState === SlotMachineState.IDLE) {
        if (isFreeSpinMode(setGameMode())) return;
        if (setGameMode() !== GameMode.FREE_ROUND_BONUS) {
          eventManager.emit(
            EventTypes.UPDATE_WIN_VALUE,
            formatNumber({ currency: setCurrency(), value: 0, showCurrency: showCurrency(setCurrency()) }),
          );
        }
        if (setIsAutoSpins()) setAutoSpinsLeft(setAutoSpinsLeft() - 1);
        client.writeQuery({
          query: isStoppedGql,
          data: {
            isSlotStopped: false,
          },
        });
        const payload = {
          variables: {
            input: {
              slotId,
              coinAmount: setBetAmount() / 20,
              coinValue: setCoinValue(),
              lineSetId: setSlotConfig().lineSets[0]!.id,
            } as IPlaceBetInput,
          },
        };
        if (freeRoundBonus) {
          payload.variables.input.userBonusId = setCurrentBonus().id;
        }
        fnGet(payload);
        setIsSpinInProgress(true);
        setIsSlotBusy(true);
        AudioApi.play({ type: ISongs.SFX_UI_SpinStart, stopImmediately: [ISongs.SFX_UI_Close] });
        if (AudioApi.isRestricted) {
          AudioApi.unSuspend();
          AudioApi.processRestriction({
            restricted: false,
            listToPlay: [{ type: ISongs.BaseGameBGM_Base }, { type: ISongs.BaseGameBGM_Melo, volume: 0 }],
          });
        }
      } else {
        client.writeQuery({
          query: isStoppedGql,
          data: {
            isSlotStopped: true,
          },
        });
      }

      if (AudioApi.isRestricted) {
        const soundToPlay = getSoundToPlay();
        AudioApi.unSuspend();
        AudioApi.processRestriction({
          restricted: false,
          listToPlay: soundToPlay,
          beforeSetQueue: () => setIsSoundLoading(true),
          onChangeRestriction: () => setIsShowSoundToast(false),
        });
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    [dataBet?.betAmount, fnGet, lineSet, slotId],
  );
  useEffect(() => {
    const freeSpin = () => {
      SlotMachine.getInstance().spin(isTurboSpin);
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: false,
        },
      });
      fnGet({
        variables: {
          input: {
            slotId,
            coinAmount: setBetAmount() / 20,
            coinValue: setCoinValue(),
            lineSetId: setSlotConfig().lineSets[0]!.id,
            userBonusId: setCurrentBonusId(),
          },
        },
      });
      setIsSpinInProgress(true);
      setIsSlotBusy(true);
      AudioApi.play({ type: ISongs.SFX_UI_SpinStart });
    };
    const buyFeatureSpin = () => {
      setIsBuyFeaturePurchased(false);
      setWinAmount(0);
      setLastRegularWinAmount(0);
      SlotMachine.getInstance().spin(isTurboSpin);
      fnGet({
        variables: {
          input: {
            slotId,
            coinAmount: setCoinAmount(),
            coinValue: setCoinValue(),
            lineSetId: setSlotConfig().lineSets[0]!.id,
            userBonusId: setCurrentBonusId(),
          },
        },
      });
      setIsSpinInProgress(true);
      setIsSlotBusy(true);
      AudioApi.play({ type: ISongs.SFX_UI_SpinStart });
      eventManager.emit(
        EventTypes.UPDATE_WIN_VALUE,
        formatNumber({ currency: setCurrency(), value: 0, showCurrency: showCurrency(setCurrency()) }),
      );
      if (AudioApi.isRestricted) {
        AudioApi.unSuspend();
        AudioApi.processRestriction({
          restricted: false,
          listToPlay: [{ type: ISongs.BaseGameBGM_Base }, { type: ISongs.BaseGameBGM_Melo, volume: 0 }],
        });
      }
    };
    const pickemSpin = () => {
      fnGet({
        variables: {
          input: {
            slotId,
            coinAmount: setCoinAmount(),
            coinValue: setCoinValue(),
            lineSetId: setSlotConfig().lineSets[0]!.id,
            userBonusId: setCurrentBonusId(),
          },
        },
      });
    };
    eventManager.on(EventTypes.PICKEM_SHOT, pickemSpin);
    eventManager.on(EventTypes.NEXT_FREE_SPINS_ROUND, freeSpin);
    eventManager.on(EventTypes.START_BUY_FEATURE_ROUND, buyFeatureSpin);
    return () => {
      eventManager.removeListener(EventTypes.START_BUY_FEATURE_ROUND, buyFeatureSpin);
      eventManager.removeListener(EventTypes.NEXT_FREE_SPINS_ROUND, freeSpin);
      eventManager.removeListener(EventTypes.PICKEM_SHOT, pickemSpin);
    };
  }, [onSpin, isTurboSpin]);

  const checkAutoSpinSettings = useCallback(
    (isSpaceClick?: boolean) => {
      if (setIsAutoSpins()) {
        const autoSpinsLeft = setAutoSpinsLeft() <= 0;
        const bonus =
          setIsStopOnFeatureWin() &&
          setCurrentBonus().isActive &&
          setCurrentBonus().gameMode !== GameMode.FREE_ROUND_BONUS;
        const stopOnWin = setIsStopOnAnyWin() && setLastRegularWinAmount();

        const stopOnWinExceeds = setIsStopOnWinExceeds() && setLastRegularWinAmount() >= setStopOnWinExceeds();

        const balanceIncrease =
          setIsStopOnBalanceIncrease() &&
          balanceAmount &&
          setStopOnBalanceIncrease() * setCoinValue() <= balanceAmount - setAutoSpinsStartBalance();

        const balanceDecrease =
          setIsStopOnBalanceDecrease() &&
          balanceAmount &&
          setStopOnBalanceDecrease() * setCoinValue() <= setAutoSpinsStartBalance() - balanceAmount;

        if (autoSpinsLeft || bonus || stopOnWin || stopOnWinExceeds || balanceIncrease || balanceDecrease) {
          setIsAutoSpins(false);
          if (!isSpaceClick) {
            eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
          }
        } else {
          onSpin(isTurboSpin);
        }
      }
    },
    [balanceAmount, onSpin, isTurboSpin],
  );

  useEffect(() => {
    if (isAutoSpins && setIsFreeSpinsWin()) {
      if (setIsStopOnFeatureWin()) {
        setIsContinueAutoSpinsAfterFeature(false);
        setAutoSpinsLeft(0);
      } else {
        setIsContinueAutoSpinsAfterFeature(true);
      }
      setIsAutoSpins(false);
    }
  }, [isFreeSpinsWin, setIsContinueAutoSpinsAfterFeature()]);

  const onSpinButtonClick = useCallback(() => {
    if (
      (setGameMode() === GameMode.REGULAR ||
        setGameMode() === GameMode.BUY_FEATURE ||
        setGameMode() === GameMode.FREE_ROUND_BONUS) &&
      setIsFreeSpinsWin()
    ) {
      return;
    }

    onSpin(isTurboSpin);
  }, [isAutoSpins, isTurboSpin, onSpin]);

  const useHandleSpaceSpin = useCallback(
    (e: KeyboardEvent) => {
      if (e.keyCode === 32 && !stressful?.stressful.show) {
        e.preventDefault();
        e.stopPropagation();

        eventManager.emit(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);

        if (
          !canPressSpin({
            gameMode,
            isFreeSpinsWin: setIsFreeSpinsWin(),
            isSpinInProgress: setIsSpinInProgress(),
            isSlotBusy: setIsSlotBusy(),
            isSlotStopped: dataSlotStopped?.isSlotStopped ?? false,
            isBuyFeaturePopupOpened: setIsBuyFeaturePopupOpened(),
            isPickem: gameMode === GameMode.PICKEM,
            brokenBuyFeature: setBrokenBuyFeature(),
            isPopupOpened: setIsPopupOpened(),
            transitionStarted: setIsTransitionStarted(),
          })
        ) {
          return;
        }

        if (isAutoSpins) {
          checkAutoSpinSettings(true);
          return;
        }
        if (!isFreeSpinModeOnTotalWinBannerStep()) {
          onSpin(isTurboSpin);
        }
      }
    },
    [
      gameMode,
      isAutoSpins,
      dataSlotStopped?.isSlotStopped,
      data?.isEnabledSpaceSpin,
      progress?.wasLoaded,
      checkAutoSpinSettings,
      onSpin,
      isTurboSpin,
      stressful?.stressful,
    ],
  );

  useEffect(() => {
    window.addEventListener('keydown', useHandleSpaceSpin);
    return () => window.removeEventListener('keydown', useHandleSpaceSpin);
  }, [useHandleSpaceSpin]);

  useEffect(() => {
    const play = _.reduce(winThreeTimes, (acc, item) => acc && item);
    const stop = !_.reduce(winThreeTimes, (acc, item) => acc || item);

    if (play) {
      AudioApi.fadeIn(500, ISongs.BaseGameBGM_Melo);
      // AudioApi.fadeOut(
      //   500,
      //   ISongs.BaseGameBGM_Base,
      //   audioSpriteVolume[ISongs.BaseGameBGM_Base] / 3,
      // );
    }

    if (stop) {
      AudioApi.fadeOut(1000, ISongs.BaseGameBGM_Melo);
      // AudioApi.fadeIn(
      //   3000,
      //   ISongs.BaseGameBGM_Base,
      //   audioSpriteVolume[ISongs.BaseGameBGM_Base],
      // );
    } else {
      timeout = setTimeout(() => {
        AudioApi.fadeOut(1000, ISongs.BaseGameBGM_Melo);

        // AudioApi.fadeIn(
        //   3000,
        //   ISongs.BaseGameBGM_Base,
        //   audioSpriteVolume[ISongs.BaseGameBGM_Base],
        // );
      }, 30000);
    }
  }, [winThreeTimes]);

  const isSlotBusy = useReactiveVar(setIsSlotBusy);
  useEffect(() => {
    let id: NodeJS.Timeout;
    if (!setIsFreeSpinsWin() && setIsContinueAutoSpinsAfterFeature()) {
      setIsAutoSpins(true);
      setIsContinueAutoSpinsAfterFeature(false);
    }
    if (dataSlotStopped?.isSlotStopped && !isSlotBusy) {
      id = setTimeout(
        () => {
          if (!stressful?.stressful.show) {
            checkAutoSpinSettings();
          }
        },
        setAutoSpinsLeft() === setAutoSpinsAmount() ? 0 : config.autoplay.timeOut,
      );
    }
    return () => clearTimeout(id);
  }, [isAutoSpins, isFreeSpinsWin, checkAutoSpinSettings, dataSlotStopped?.isSlotStopped, isSlotBusy]);

  useEffect(() => {
    eventManager.on(EventTypes.TOGGLE_SPIN, () => {
      onSpinButtonClick();
    });

    return () => {
      eventManager.removeListener(EventTypes.TOGGLE_SPIN);
    };
  }, [onSpinButtonClick, isAutoSpins, isTurboSpin]);

  return null;
};

export default Spin;
