import i18n from 'i18next';
import { Graphics } from 'pixi.js';

import { TextField } from '../components/TextField';
import ViewContainer from '../components/container';
import { soundToastStyles } from '../config';

export class Toast extends ViewContainer {
  private rect = this.initRect();

  private text = this.initText();

  constructor() {
    super();

    this.visible = false;
    this.zIndex = 3;
    this.addChild(this.rect);
    this.rect.addChild(this.text.getText());
  }

  private initRect(): Graphics {
    const rect = new Graphics();
    rect.beginFill(0x000000, 0.7);
    rect.drawRoundedRect(0, 0, 360, 126, 65);
    rect.endFill();

    return rect;
  }

  private initText(): TextField {
    const text = new TextField('Sound is <color>OFF</color>', 270, 60, {}, true, soundToastStyles);
    text.text.anchor.set(0.5);
    text.text.x = 180;
    text.text.y = 63;
    return text;
  }

  public setIsLoading(): void {
    this.visible = true;
    this.text.setText(i18n.t('soundIsLoading'));
  }

  public setIsOff(): void {
    this.visible = true;
    this.text.setText(i18n.t('soundIsOff'));
    setTimeout(() => (this.visible = false), 3000);
  }

  public setIsOn(): void {
    this.visible = true;
    this.text.setText(i18n.t('soundIsOn'));
    setTimeout(() => (this.visible = false), 1000);
  }

  public setIsOnOff(soundIsOn: boolean): void {
    this.visible = true;
    if (soundIsOn) {
      this.text.setText(i18n.t('soundIsOn'));
    } else {
      this.text.setText(i18n.t('soundIsOff'));
    }

    setTimeout(() => (this.visible = false), 1000);
  }
}
