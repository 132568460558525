import _ from 'lodash';

import type { TAudioSprite } from '@phoenix7dev/audio-api/dist/d';

export enum ISongs {
  BaseGameBGM_Base = '007_01_BGM_BG_Base_Loop',
  BaseGameBGM_Melo = '007_02_BGM_BG_Melo_Loop',
  // FreeSpinBGM_Intro = '002_03_FreeSpinBGM_Intro',
  PickemBonusBGM_Loop = '007_03_BGM_PB_Loop',
  FreeSpinBGM_Loop = '007_04_BGM_FS_Loop',
  PickemBonusEntrancePopup = '007_05_PB_EntrancePopup',
  FreeSpinEntrancePopup = '007_06_FS_EntrancePopup',
  FreeSpinExitPopup = '007_07_FS_ExitPopup', // Equals TotalWinBanner
  // BigWin_Intro = '002_04_BigWin_Intro',
  BigWin_Loop = '007_08_BigWin_Loop',
  BigWin_End = '007_09_BigWin_End',
  SFX_WIN_BIG = '007_10_Win_Big',
  SFX_WIN_MEGA = '007_11_Win_Mega',
  SFX_WIN_GREAT = '007_12_Win_Great',
  SFX_WIN_EPIC = '007_13_Win_Epic',
  Small_Win = '007_14_SmallWin',
  Medium_Win = '007_15_MediumWin',
  High_Win = '007_16_HighWin',
  Scatter_01 = '007_17_Scatter_01',
  Scatter_02 = '007_17_Scatter_02',
  Scatter_03 = '007_17_Scatter_03',
  Wild_Landing = '007_20_WildLanding',
  FeatureTrigger = '007_21_FeatureTrigger',
  LongSpin = '007_22_Anticipation',
  PickemBonusShot = '007_23_PB_Pick_Shoot_Bottle',
  FreeSpinsWildLanding = '007_26_PB_WildStick',
  BuyButton = '007_27_BuyButton',
  Win_End = 'SFX_SM_CountUp_End',
  Win_Loop = 'SFX_SM_CountUp_Loop',
  SFX_UI_AutoSpin = 'SFX_UI_AutoSpin',
  SFX_UI_BetChange = 'SFX_UI_BetChange',
  SFX_UI_Close = 'SFX_UI_Close',
  SFX_UI_General = 'SFX_UI_General',
  SFX_UI_Hover = 'SFX_UI_Hover',
  SFX_UI_MaxBet = 'SFX_UI_MaxBet',
  SFX_UI_MenuOpen = 'SFX_UI_MenuOpen',
  SFX_UI_SpinStart = 'SFX_UI_SpinStart',
  SFX_UI_SpinStop = 'SFX_UI_SpinStop',
  Phoenix_Anticipation = 'Phoenix_Anticipation',
}

export const audioSprite: TAudioSprite = {
  [ISongs.BaseGameBGM_Base]: [0, 69818.68480725623, true],
  [ISongs.BaseGameBGM_Melo]: [71000, 69818.68480725623, true],
  [ISongs.PickemBonusBGM_Loop]: [142000, 64001.36054421768, true],
  [ISongs.FreeSpinBGM_Loop]: [208000, 99096.00907029476, true],
  [ISongs.PickemBonusEntrancePopup]: [309000, 3825.3514739229217],
  [ISongs.FreeSpinEntrancePopup]: [314000, 6899.999999999977],
  [ISongs.FreeSpinExitPopup]: [322000, 6377.369614512474],
  [ISongs.BigWin_Loop]: [330000, 30720.00000000003, true],
  [ISongs.BigWin_End]: [362000, 1185.3514739228785],
  [ISongs.SFX_WIN_BIG]: [365000, 2201.360544217664],
  [ISongs.SFX_WIN_MEGA]: [369000, 2193.333333333328],
  [ISongs.SFX_WIN_GREAT]: [373000, 2201.360544217664],
  [ISongs.SFX_WIN_EPIC]: [377000, 2185.3514739228785],
  [ISongs.Small_Win]: [381000, 1785.3514739229013],
  [ISongs.Medium_Win]: [384000, 1785.3514739229013],
  [ISongs.High_Win]: [387000, 2334.6938775510466],
  [ISongs.Scatter_01]: [391000, 546.6666666666811],
  [ISongs.Scatter_02]: [393000, 556.009070294806],
  [ISongs.Scatter_03]: [395000, 553.3333333333417],
  [ISongs.Wild_Landing]: [397000, 1092.0181405895732],
  [ISongs.FeatureTrigger]: [400000, 2188.027210884343],
  [ISongs.LongSpin]: [404000, 2184.0362811791465],
  [ISongs.PickemBonusShot]: [408000, 1657.3696145124472],
  [ISongs.FreeSpinsWildLanding]: [411000, 697.3696145124677],
  [ISongs.BuyButton]: [413000, 1100.0000000000227],
  [ISongs.Phoenix_Anticipation]: [416000, 4033.33333333336],
  [ISongs.Win_End]: [422000, 933.4693877551103],
  [ISongs.Win_Loop]: [424000, 399.77324263037417, true],
  [ISongs.SFX_UI_AutoSpin]: [426000, 320.4988662131427],
  [ISongs.SFX_UI_BetChange]: [428000, 32.108843537400844],
  [ISongs.SFX_UI_Close]: [430000, 200.680272108854759],
  [ISongs.SFX_UI_General]: [432000, 88.34467120180989],
  [ISongs.SFX_UI_Hover]: [434000, 151.79138321997243],
  [ISongs.SFX_UI_MaxBet]: [436000, 122.94784580501528],
  [ISongs.SFX_UI_MenuOpen]: [438000, 277.1428571428487],
  [ISongs.SFX_UI_SpinStart]: [440000, 249.61451247168043],
  [ISongs.SFX_UI_SpinStop]: [442000, 151.02040816327644],
};

export const audioSpriteVolume: Record<ISongs, number> = {
  [ISongs.BaseGameBGM_Base]: 0.1,
  [ISongs.BaseGameBGM_Melo]: 0.2,
  // [ISongs.FreeSpinBGM_Intro]: 0.3,
  [ISongs.PickemBonusBGM_Loop]: 0.25,
  [ISongs.FreeSpinBGM_Loop]: 0.35,
  [ISongs.PickemBonusEntrancePopup]: 0.55,
  [ISongs.FreeSpinEntrancePopup]: 0.55,
  [ISongs.FreeSpinExitPopup]: 0.55,
  // [ISongs.BigWin_Intro]: 0.3,
  [ISongs.BigWin_Loop]: 0.65,
  [ISongs.BigWin_End]: 0.5,
  [ISongs.SFX_WIN_BIG]: 0.5,
  [ISongs.SFX_WIN_EPIC]: 0.5,
  [ISongs.SFX_WIN_GREAT]: 0.5,
  [ISongs.SFX_WIN_MEGA]: 0.5,
  [ISongs.Small_Win]: 0.5,
  [ISongs.Medium_Win]: 0.5,
  [ISongs.High_Win]: 0.5,
  [ISongs.Scatter_01]: 0.55,
  [ISongs.Scatter_02]: 0.55,
  [ISongs.Scatter_03]: 0.55,
  [ISongs.Wild_Landing]: 0.55,
  [ISongs.FeatureTrigger]: 0.5,
  [ISongs.LongSpin]: 0.5,
  [ISongs.PickemBonusShot]: 0.75,
  [ISongs.FreeSpinsWildLanding]: 0.55,
  [ISongs.BuyButton]: 0.4,
  [ISongs.Win_End]: 0.4,
  [ISongs.Win_Loop]: 0.5,
  [ISongs.SFX_UI_AutoSpin]: 0.4,
  [ISongs.SFX_UI_BetChange]: 0.4,
  [ISongs.SFX_UI_Close]: 0.4,
  [ISongs.SFX_UI_General]: 0.4,
  [ISongs.SFX_UI_Hover]: 0.4,
  [ISongs.SFX_UI_MaxBet]: 0.4,
  [ISongs.SFX_UI_MenuOpen]: 0.4,
  [ISongs.SFX_UI_SpinStart]: 0.3,
  [ISongs.SFX_UI_SpinStop]: 0.3,
  [ISongs.Phoenix_Anticipation]: 0.5,
};

export const audioSpriteGaps: { [key in ISongs]?: number } = {
  // [ISongs.Music_BigWin_End]: 2000,
};

export const mappedAudioSprites = _.reduce(
  audioSprite,
  (acc, value, key) => {
    _.set(acc, key, {
      start: value[0],
      duration: value[1] - (audioSpriteGaps[key as ISongs] || 0),
      loop: !!value[2],
    });
    return acc;
  },
  {},
) as {
  [key in ISongs]: { start: number; duration: number; loop: boolean };
};
