import * as PIXI from 'pixi.js';

import { Variables } from '../../config';

export const textStyle = new PIXI.TextStyle({
  dropShadowAlpha: 0.8,
  dropShadowAngle: 1,
  dropShadowBlur: 10,
  dropShadowDistance: 8,
  fill: '#FFCC48',
  fillGradientStops: [0.4],
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 52,
  fontWeight: '900',
  lineJoin: 'round',
  miterLimit: 65,
  strokeThickness: 12,
  whiteSpace: 'normal',
});

export const spinsStyle = new PIXI.TextStyle({
  dropShadowAlpha: 0.8,
  dropShadowAngle: 1,
  dropShadowBlur: 10,
  dropShadowDistance: 8,
  fill: '#fff',
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 52,
  fontWeight: '900',
  lineJoin: 'round',
  miterLimit: 65,
  strokeThickness: 12,
  whiteSpace: 'normal',
});
