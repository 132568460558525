// import i18n from 'i18next';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { introContents } from '../../config/introContents';
import { EventTypes, GameMode } from '../../global.d';
import { setBrokenGame, setIsProcessToGame, setIsShowSoundToast, setIsSoundLoading, setProgress } from '../../gql';
import { setBrokenPickem, setGameMode } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import { eventManager } from '../../slotMachine/config';
import SoundBtn from '../../slotMachine/controlButtons/soundBtn';
import { UiButton } from '../../slotMachine/ui/uiButton';
import { calcPercentage, getCssVariable } from '../../utils';

import Carousel from './Carousel/index';

class IntroScreen {
  private readonly application: PIXI.Application;

  private container = new PIXI.Container();

  private static introScreen: IntroScreen;

  public static initIntroScreen = (application: PIXI.Application): void => {
    IntroScreen.introScreen = new IntroScreen(application);
  };

  public static getInstance = (): IntroScreen | null => IntroScreen.introScreen;

  private background = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.introBg));

  private backgroundContainer = new PIXI.Container();

  private playBtn: UiButton;

  private soundBtn: SoundBtn;

  private carousel: Carousel;

  private bindedResize = this.resize.bind(this);

  private constructor(application: PIXI.Application) {
    this.application = application;
    this.background.anchor.set(0.5);
    this.backgroundContainer.addChild(this.background);

    (globalThis as unknown as { __PIXI_APP__: unknown }).__PIXI_APP__ = this.application;
    this.container = new PIXI.Container();
    this.container.sortableChildren = true;
    this.soundBtn = new SoundBtn();
    this.carousel = new Carousel(introContents);
    this.playBtn = this.initPlayBtn();
    this.container.addChild(this.carousel, this.playBtn, this.soundBtn);
    this.application.stage.addChild(this.backgroundContainer, this.container);
    eventManager.addListener(EventTypes.RESIZE, this.bindedResize);
  }

  public getApplication(): PIXI.Application {
    return this.application;
  }

  private initPlayBtn = (): UiButton => {
    const btn = new UiButton('play');
    btn.btn.anchor.set(1, 1);
    btn.interactive = true;

    const clickCallback = () => {
      let soundToPlay;

      if (setBrokenPickem()) {
        soundToPlay = [{ type: ISongs.PickemBonusBGM_Loop }];
      } else if (setBrokenGame() && setGameMode() === GameMode.FREE_SPINS) {
        soundToPlay = [{ type: ISongs.FreeSpinBGM_Loop }];
      } else {
        soundToPlay = [{ type: ISongs.BaseGameBGM_Base }, { type: ISongs.BaseGameBGM_Melo, volume: 0 }];
      }

      AudioApi.unSuspend();
      AudioApi.processRestriction({
        restricted: false,
        listToPlay: soundToPlay,
        beforeSetQueue: () => setIsSoundLoading(true),
        onChangeRestriction: () => setIsShowSoundToast(false),
      });

      setProgress({ ...setProgress(), wasLoaded: true });

      setTimeout(() => {
        this.destroy();
        eventManager.emit(EventTypes.HANDLE_DESTROY_INTRO_SCREEN);
      });
    };

    btn.on('click', clickCallback);
    btn.on('touchstart', clickCallback);

    return btn;
  };

  private setBgSize = (width: number, height: number): void => {
    this.backgroundContainer.x = width / 2;
    this.backgroundContainer.y = height / 2;

    const bgAspectRatio = this.background.width / this.background.height;
    const aspectRatio = width / height;
    if (bgAspectRatio > aspectRatio) {
      this.backgroundContainer.scale.set(height / this.background.height);
    } else {
      this.backgroundContainer.scale.set(width / this.background.width);
    }
  };

  private resize(width: number, height: number): void {
    this.application.renderer.resize(width, height);
    const isPortraitMode = width < height;
    const gap = calcPercentage(width, 1.25);
    const playBtnGap = calcPercentage(width, 5);
    this.setBgSize(width, height);
    const heightWithBottomGap = isPortraitMode
      ? calcPercentage(height, 100 - parseInt(getCssVariable('--bottom-height-percent-portrait'), 10))
      : calcPercentage(height, 100 - parseInt(getCssVariable('--bottom-height-percent-landscape'), 10));
    this.playBtn.btn.anchor.set(1, 1);
    this.playBtn.setSize(calcPercentage(width, 13));
    this.playBtn.x = width - playBtnGap;
    this.playBtn.y = height - this.playBtn.height / 2;

    this.soundBtn.btn.anchor.set(0, 0);
    this.soundBtn.x = gap;
    this.soundBtn.y = gap;
    this.soundBtn.setSize(calcPercentage(width, 7.5));
    this.carousel.setSize(width, height, height - heightWithBottomGap);
    if (isPortraitMode) {
      this.soundBtn.btn.anchor.set(1, 0);
      this.soundBtn.x = width - gap;
      this.soundBtn.y = gap;
      this.soundBtn.setSize(calcPercentage(height, 7.5));

      this.playBtn.setSize(calcPercentage(width, 40));
      this.playBtn.btn.anchor.set(0.5, 0);
      this.playBtn.y = this.carousel.getBounds().y + this.carousel.height + 10;
      this.playBtn.x = width / 2;
    }
  }

  private destroy(): void {
    this.application.stage.removeChild(this.backgroundContainer);
    this.application.stage.removeChild(this.container);
    setIsProcessToGame(true);
    eventManager.removeListener(EventTypes.RESIZE, this.bindedResize);
  }
}

export default IntroScreen;
