import type { RemoteStorage } from '@phoenix7dev/setting-store-client';

import { SlotId } from './config';
import { ReelSetType } from './gql/d';
import { Icon, LineSet } from './slotMachine/d';

export interface IUserBalance {
  id: string;
  clientId: string;
  balance: {
    amount: number;
    currency: string;
  };
}

export interface IAuthInput {
  clientId: string;
  token: string;
  slotId: string;
  lng: string;
  home: string;
}

export interface IGetBonusesInput {
  id: string;
  purchasable: boolean;
}

export interface IBuyBonusesInput {
  id: string;
  coinValue: number;
}

export interface ISettledBet {
  bet: {
    id: string;
    coinAmount: number;
    coinValue: number;
    slotId: string;
    result: {
      reelPositions: number[];
      winCoinAmount: number;
      spinResult: Icon[];
    };
    userBonusId: string;
    userBonus: {
      id: string;
      bonusId: string;
      lineSet: LineSet;
    };
    reelSetId: string;
    reelSet: ReelSet;
    lineSet: LineSet;
    data: {
      isBonus: boolean;
      bonuses: UserBonus[];
      features: {
        isFreeSpins: boolean;
        multipliers: {
          [key: number]: number;
        };
        bonusData: {
          preLoadedGrantBreakdown: PreLoadedGrantBreakdown[];
        };
      };
    };
    createdAt: string;
    updatedAt: string;
  };
  paylines: {
    lineId: number;
    winPositions: number[];
    amount: number;
  }[];
  balance: {
    placed: {
      amount: number;
      currency: string;
    };
    settled: {
      amount: number;
      currency: string;
    };
  };
  rewards: BetReward[];
}
export type BetRewardType = 'BetBonusReward' | 'BetCoinReward';
export type BetBonusReward = {
  __typename: BetRewardType;
  userBonusId: string;
  userBonus?: UserBonus;
};

export type BetCoinReward = {
  __typename: BetRewardType;
  amount: number;
};
export type BetReward = BetBonusReward | BetCoinReward;

export type ReelSet = {
  id: string;
  layout: SlotId[][];
  type: ReelSetType;
  additionalReelSets: SlotId[][];
};

export type Grants = {
  count: number;
  id: string;
  type: string;
};

export type PreLoadedGrantBreakdown = {
  triggerId: string;
  grants: {
    grants: Grants[];
    type: string;
  }[];
};

export type PreLoadedGrants = {
  triggerId: string;
};

export type UserBonusData = {
  betId: string;
  count: number;
  preLoadedGrantBreakdown: PreLoadedGrantBreakdown[];
};

export type UserBonus = {
  bonusId: string;
  id: string;
  isActive: boolean;
  currentRound: number;
  gameMode: GameMode;
  betId: string;
  rounds: number;
  reelSetId: string;
  status: UserBonusStatus;
  totalWinAmount;
  data: UserBonusData;
  coinAmount: number;
  coinValue: number;
  isFreeBet?: boolean;
  bonus?: {
    reelSetId?: string;
  };
};
export enum GameMode {
  REGULAR,
  PICKEM,
  FREE_SPINS,
  BUY_FEATURE_FREE_SPINS,
  BUY_FEATURE,
  FREE_ROUND_BONUS,
}

export enum UserBonusStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SETTLED = 'SETTLED',
}

export const baseReelSetIds = ['6c6b7036-b625-4ad0-8ba7-512e50036ac2', '5947922b-7895-474c-8f4e-6f6f0494910e'];

export const freeSpinReelSetIds = ['d046d091-75ee-4d1c-bbb2-5887f3344d6d', '8a1492f6-eff4-4391-906d-e06f1818c9ec'];

export const reelSets = {
  [GameMode.PICKEM]: '4d882349-301a-4169-82f9-4fd7c276dbd8',
  [GameMode.BUY_FEATURE]: '561d9fec-a97e-4599-8808-35c22af4f85c',
};
export const bonusesId = {
  [GameMode.PICKEM]: '1e75528b-5706-46b9-bb01-acbc165bab77',
  [GameMode.BUY_FEATURE]: '4bd33096-14a3-4161-8813-2d4900a3689a',
  [GameMode.FREE_SPINS]: '86b144c5-6ee0-4555-98e5-9cd463191f93',
  [GameMode.FREE_ROUND_BONUS]: '6a3a99d5-8988-488a-8af3-1d5ed72716f1',
};
export interface IBonus {
  id: string;
  type: string;
  coinAmount: number;
}
export interface GetUserBonusesInput {
  id: string;
  status: BonusStatus;
}

export type FreeSpinsTitleProps = {
  text: string;
  spins: string;
  currentSpin: string;
};

export enum MessageBannerTypes {
  Pickem,
  Freespin,
  Total,
}

export type MessageBannerProps = {
  bannerBg: string;
  title?: {
    text: string;
    position: 0;
    styles: PIXI.TextStyle;
  };
  subtitle?: {
    text: string;
    position: 0;
    styles: PIXI.TextStyle;
  };
  additionalText?: {
    text: string;
    position: 0;
    styles: PIXI.TextStyle;
  };
  btn?: {
    text: string;
    position: 0;
    styles: PIXI.TextStyle;
  };
  value?: {
    text: string;
    position: 0;
    styles: PIXI.TextStyle;
  };
  preventDefaultDestroy?: boolean;
  type?: MessageBannerTypes;
  callback?: () => void;
  onInitCallback?: () => void;
};

declare global {
  interface Window {
    eventManager: PIXI.utils.EventEmitter<string | symbol>;
    __ENV__: {
      ENV: string;
      STATIC_CDN_URL: string;
      STATIC_CDN_DIRECTORY: string;
      APP_VERSION: string;
      SENTRY_ENABLED: boolean;
      SENTRY_DSN: string;
      APP_NAME: string;
      NETWORK_RETRY_ATTEMPTS: number;
      NETWORK_RETRY_DELAY: number;
    };
    __PIXI_APP__: PIXI.Application;
    remoteStorage: RemoteStorage;
  }
}

export enum EventTypes {
  ANTICIPATION_STARTS = 'anticipationStarts',
  BREAK_SPIN_ANIMATION = 'breakSpinAnimation',
  COUNT_UP_END = 'countUpEnd',
  CHANGE_MODE = 'changeMode',
  CHANGE_REEL_SET = 'changeReelSet',
  CREATE_FREE_SPINS_TITLE = 'createFreeSpinsTitle',
  CREATE_MESSAGE_BANNER = 'createMessageBanner',
  DISABLE_ALL_MINI_PAY_TABLES = 'disableAllMiniPayTables',
  DISABLE_PAYTABLE = 'disablePaytable',
  END_TWEEN_ANIMATION = 'endTweenAnimation',
  END_RETRIGGER_FEATURE = 'endRetriggerFeature',
  FORCE_STOP_REELS = 'forceStopReels',
  HIDE_WIN_LINES = 'hideWinLines',
  HIDE_WIN_LABEL = 'hideWinLabel',
  HIDE_WIN_COUNT_UP_MESSAGE = 'hideWinCountUpMessage',
  HANDLE_SKIP_FADE_ANIMATION = 'handleSkipFadeAnimation',
  HANDLE_START_FADE_ANIMATION = 'handleStartFadeAnimation',
  HANDLE_BUY_BONUS = 'handleBuyBonus',
  HANDLE_UPDATE_FREE_SPINS_TITLE = 'handleUpdateFreeSpinsTitle',
  HANDLE_IS_ACTIVE_FREE_SPINS_GAME = 'handleIsActiveFreeSpinsGame',
  NEXT_FREE_SPINS_ROUND = 'nextFreeSpinsRound',
  SPACEKEY_CLOSE_MESSAGE_BANNER = 'spaceKeyCloseMessageBanner',
  MANUAL_DESTROY_MESSAGE_BANNER = 'manualDestroyMessageBanner',
  MANUAL_CHANGE_BACKGROUND = 'manualChangeBackground',
  REELS_STOPPED = 'reelsStopped',
  REGISTER_ANIMATOR = 'registerAnimator',
  RESET_SLOT_MACHINE = 'resetSlotMachine',
  RESIZE = 'resize',
  RESIZE_GAME_CONTAINER = 'resizeGameContainer',
  REMOVE_TWEEN_ANIMATION = 'removeTweenAnimation',
  REMOVE_FREE_SPINS_TITLE = 'removeFreeSpinsTitle',
  REMOVE_ANIMATOR = 'removeAnimator',
  ROLLBACK_REELS = 'rollbackReels',
  SLOT_MACHINE_STATE_CHANGE = 'slotMachineStateChange',
  SET_SLOTS_VISIBILITY = 'setSlotsVisibility',
  SET_WIN_LABEL_TEXT = 'setWinLabelText',
  START_TWEEN_ANIMATION = 'startTweenAnimation',
  START_WIN_ANIMATION = 'startWinAnimation',
  START_SPIN_ANIMATION = 'startSpinAnimation',
  START_MODE_CHANGE_FADE = 'startModeChangeFade',
  START_RETRIGGER_FEATURE = 'startRetriggerFeature',
  START_BUY_FEATURE_ROUND = 'startBuyFeatureRound',
  SHOW_WIN_LINES = 'showWinLines',
  SHOW_TINT = 'showTint',
  SHOW_PAY_TABLE = 'showPayTable',
  SHOW_WIN_LABEL = 'showWinLabel',
  SET_BIG_WIN_VISIBILITY = 'setBigWinVisibility',
  SET_MEGA_WIN_VISIBILITY = 'setMegaWinVisibility',
  SET_GREAT_WIN_VISIBILITY = 'setGreatWinVisibility',
  SET_EPIC_WIN_VISIBILITY = 'setEpicWinVisibility',
  SETUP_REEL_POSITIONS = 'setupReelPositions',
  SKIP_ALL_WIN_ANIMATIONS = 'skipAllWinAnimations',
  SKIP_WIN_COUNT_UP_ANIMATION = 'skipWinCountUpAnimation',
  SKIP_WIN_SLOTS_ANIMATION = 'skipWinSlotsAnimation',
  THROW_ERROR = 'throwError',
  UPDATE_BET = 'updateBet',
  UPDATE_USER_BALANCE = 'updateUserBalance',
  UPDATE_TOTAL_WIN_VALUE = 'updateTotalWinValue',
  UPDATE_WIN_VALUE = 'updateWinValue',
  POST_RENDER = 'postrender',
  OPEN_BUY_FEATURE_POPUP = 'openBuyFeaturePopup',
  SHOW_BACKDROP = 'showBackDrop',
  OPEN_BUY_FEATURE_CONFIRM_POPUP = 'openBuyFeatureConfirmPopup',
  OPEN_BUY_FEATURE_CONFIRM_POPUP_BG = 'openBuyFeatureConfirmPopupBg',
  CLOSE_BUY_FEATURE_POPUP = 'closeBuyFeaturePopup',
  HIDE_BACKDROP = 'hideBackDrop',
  CLOSE_BUY_FEATURE_CONFIRM_POPUP = 'closeBuyFeatureConfirmPopup',
  CLOSE_BUY_FEATURE_CONFIRM_POPUP_BG = 'closeBuyFeatureConfirmPopupBg',
  DISABLE_BUY_FEATURE_BTN = 'disableBuyFeatureBtn',
  SET_IS_AUTO_SPINS = 'setIsAutoSpins',
  SET_AUTO_SPINS_LEFT = 'setAutoSpinsLeft',
  SET_IS_SPIN_IN_PROGRESS = 'isSpinInProgress',
  SET_IS_SLOT_IS_BUSY = 'isSlotBusy',
  SET_IS_SLOTS_STOPPED = 'isSlotsStopped',
  SET_IS_FREESPINS_WIN = 'isFreeSpinWin',
  TOGGLE_SOUND = 'toggleSound',
  TOGGLE_TURBO_SPIN = 'toggleTurboSpin',
  TOGGLE_SPIN = 'toggleSpin',
  SOUND_INITIALIZED = 'soundInitialized',
  HANDLE_DESTROY_INTRO_SCREEN = 'handleDestroyIntroScreen',
  PICKEM_SHOT = 'pickemShot',
  PICKEM_SHOT_RESOLVED = 'pickemShotResolved',
  SET_IS_SUSPENDED = 'setIsSuspended',
  OPEN_POPUP = 'openPopup',
  CLOSE_POPUP = 'closePopup',
  PHOENIX_ANTICIPATION_START = 'phoenixAnticipationStart',
  PHOENIX_ANTICIPATION_END = 'phoenixAnticipationEnd',
  FORCE_STOP_AUTOPLAY = 'forceStopAutoplay',
  FORCE_CLOSE_BUYFEATURE = 'forceCloseBuyFeature',
  START_FREE_ROUND_BONUS = 'startFreeRoundBonus',
  END_FREE_ROUND_BONUS = 'endFreeRoundBonus',
  UPDATE_FREE_ROUNDS_LEFT = 'updateFreeRoundsLeft',
  FREE_ROUND_BONUS_EXPIRED = 'freeRoundBonusExpired',
  END_MODE_CHANGE_FADE = 'endModeChangeFade',
}

export enum BonusStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SETTLED = 'SETTLED',
}

export enum FeatureTypes {
  SPECIAL_ROUND = 'SPECIAL_ROUND',
  FREE_SPIN = 'FREE_SPIN',
}
export enum GraphQLErrorsType {
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
}

export enum PopupOpeningTypes {
  MENU = 'MENU',
  AUTOPLAY_POPUP = 'AUTOPLAY_POPUP',
  BET_SETTINGS_POPUP = 'BET_SETTINGS_POPUP',
  BUY_FEATURE_POPUP = 'BUY_FEATURE_POPUP',
  NONE = null,
  INFO_POPUP = 'INFO_POPUP',
  HISTORY_POPUP = 'HISTORY_POPUP',
}
