import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs, mappedAudioSprites } from '../../config';
import { EventTypes, ISettledBet } from '../../global.d';
import { setBetAmount, setCurrency, setGameMode, setIsDuringBigWinLoop } from '../../gql';
import { isFreeSpinMode, isMobilePortrait, normalizeCoins, showCurrency } from '../../utils';
import type Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import {
  APPLICATION_FPS,
  BASE_WIN_AMOUNT_LIMIT,
  BASE_WIN_COUNT_UP_MULTIPLIER,
  BASE_WIN_TITLE_SCALE,
  BIG_WIN_AMOUNT_LIMIT,
  BIG_WIN_COUNT_UP_MULTIPLIER,
  DOUBLE_WIN_AMOUNT_LIMIT,
  EPIC_WIN_COUNT_UP_MULTIPLIER,
  GREAT_WIN_AMOUNT_LIMIT,
  GREAT_WIN_COUNT_UP_MULTIPLIER,
  MAXIMUM_FRACTION_DIGITS,
  MEGA_WIN_AMOUNT_LIMIT,
  MEGA_WIN_COUNT_UP_MULTIPLIER,
  MINIMUM_FRACTION_DIGITS,
  SLOTS_CONTAINER_HEIGHT,
  SLOT_HEIGHT,
  WIN_CHECK_TIME_DURATION_ON_FREESPIN,
  WinStages,
  eventManager,
  winValueStyles,
  winValueStyles2,
} from '../config';

class WinCountUpMessage extends PIXI.Container {
  public winValue = 0.0;

  screenWidth = 0;

  screenHeight = 0;

  public winCountUpAnimation: Animation | null = null;

  private winTitle: PIXI.Text;

  private winTitle2: PIXI.Text;

  constructor() {
    super();
    this.zIndex = 1;
    this.winTitle = new PIXI.Text(
      this.winValue.toLocaleString('en-EN', {
        minimumFractionDigits: MINIMUM_FRACTION_DIGITS,
        maximumFractionDigits: MAXIMUM_FRACTION_DIGITS,
      }),
      winValueStyles,
    );
    this.winTitle2 = new PIXI.Text(
      this.winValue.toLocaleString('en-EN', {
        minimumFractionDigits: MINIMUM_FRACTION_DIGITS,
        maximumFractionDigits: MAXIMUM_FRACTION_DIGITS,
      }),
      winValueStyles2,
    );
    this.winTitle.visible = false;
    this.winTitle2.visible = false;
    this.winTitle.y = SLOTS_CONTAINER_HEIGHT - 300;
    this.winTitle.anchor.set(0.5, 0.5);
    this.winTitle2.y = 0;
    this.winTitle2.x = 0;
    this.winTitle2.anchor.set(0.5, 0.5);
    this.winTitle.addChild(this.winTitle2);
    this.addChild(this.winTitle);
    eventManager.addListener(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION, this.skipWinCountUpAnimation.bind(this));
    eventManager.addListener(EventTypes.START_WIN_ANIMATION, this.startWinAnimation.bind(this));
    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.onStartSpinAnimation.bind(this));
    eventManager.addListener(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE, this.hideWinCountUpMessage.bind(this));
    eventManager.on(EventTypes.RESIZE, this.resize.bind(this));
  }

  private hideWinCountUpMessage(): void {
    this.winTitle.visible = false;
    this.winTitle2.visible = false;
  }

  private onStartSpinAnimation(): void {
    this.winCountUpAnimation?.skip();
  }

  private startWinAnimation(nextResult: ISettledBet): void {
    this.createWinAnimation(nextResult).start();
  }

  private skipWinCountUpAnimation() {
    this.winCountUpAnimation?.skip();
  }

  public getAdditionalBaseWinSound(bet: number, win: number): ISongs | null {
    const multiplier = win / bet;

    if (multiplier < 3) {
      return null;
    }

    if (multiplier >= 3 && multiplier < 5) {
      return ISongs.Small_Win;
    }

    if (multiplier >= 5 && multiplier < 7) {
      return ISongs.Medium_Win;
    }
    return ISongs.High_Win;
  }

  public createWinAnimation(nextResult: ISettledBet): Animation {
    const betAmount = normalizeCoins(setBetAmount());
    const winAmount = normalizeCoins(nextResult.bet.result.winCoinAmount);
    const stage = this.countStage(betAmount, winAmount);

    const animationChain = new AnimationChain({
      proceedNextAnimationOnSkip: true,
    });
    if (stage <= WinStages.BaseWin) {
      const baseWinAnimation = this.createBaseWinAnimation(winAmount, betAmount);
      const additionalWinSound = this.getAdditionalBaseWinSound(betAmount, winAmount);
      baseWinAnimation.addOnStart(() => {
        AudioApi.play({ type: ISongs.Win_Loop, stopPrev: true });
        if (additionalWinSound) {
          AudioApi.play({ type: additionalWinSound, stopPrev: true });
        }
      });
      baseWinAnimation.addOnComplete(() => {
        AudioApi.stop({ type: ISongs.Win_Loop });
        if (additionalWinSound) {
          AudioApi.stop({ type: additionalWinSound });
        }
        AudioApi.play({ type: ISongs.Win_End, stopPrev: true });
      });
      baseWinAnimation.addOnSkip(() => {
        this.setWinValue(winAmount);
        AudioApi.stop({ type: ISongs.Win_Loop });
        if (additionalWinSound) {
          AudioApi.stop({ type: additionalWinSound });
        }
        AudioApi.play({ type: ISongs.Win_End, stopPrev: true });
      });
      animationChain.appendAnimation(baseWinAnimation);
    } else {
      eventManager.emit(EventTypes.SHOW_BACKDROP);
      this.winTitle.style.fontSize = 150;
      this.winTitle2.style.fontSize = 150;
      this.winTitle.y = SLOT_HEIGHT - 80;
      if (stage >= WinStages.BigWin) {
        const bigWinAnimationGroup = new AnimationGroup();
        const bigWinAnimation = this.createBigWinAnimation(winAmount, betAmount, stage);

        setIsDuringBigWinLoop(true);
        AudioApi.fadeOut(0, ISongs.FreeSpinBGM_Loop);
        AudioApi.play({ type: ISongs.BigWin_Loop, stopPrev: true });

        bigWinAnimationGroup.addAnimation(bigWinAnimation);
        animationChain.appendAnimation(bigWinAnimationGroup);
      }
      if (stage >= WinStages.MegaWin) {
        const megaWinAnimation = this.createMegaWinAnimation(winAmount, betAmount, stage);
        animationChain.appendAnimation(megaWinAnimation);
      }
      if (stage >= WinStages.GreatWin) {
        const greatWinAnimation = this.createGreatWinAnimation(winAmount, betAmount, stage);
        animationChain.appendAnimation(greatWinAnimation);
      }
      if (stage >= WinStages.EpicWin) {
        const epicWinAnimation = this.createEpicWinAnimation(winAmount, betAmount);
        animationChain.appendAnimation(epicWinAnimation);
      }
    }

    if (isFreeSpinMode(setGameMode())) {
      const delayAnimation = Tween.createDelayAnimation(WIN_CHECK_TIME_DURATION_ON_FREESPIN);
      delayAnimation.addOnStart(() => {
        if (stage > WinStages.BaseWin) {
          setIsDuringBigWinLoop(false);
          // AudioApi.stop({ type: ISongs.BigWin_Intro });
          AudioApi.play({
            type: ISongs.BigWin_End,
            stopImmediately: [ISongs.BigWin_Loop],
            fadeOutImmediately: [ISongs.FreeSpinBGM_Loop],
          });
        }
      });
      animationChain.appendAnimation(delayAnimation);
    }

    const fadeOutAnimation = new Tween({
      propertyBeginValue: 1,
      target: 0,
      object: this.winTitle,
      // eslint-disable-next-line no-restricted-properties
      easing: (n) => Math.pow(n, 8),
      property: TweenProperties.ALPHA,
      duration: mappedAudioSprites[ISongs.BigWin_End].duration,
    });

    fadeOutAnimation.addOnStart(() => {
      if (stage > 1) {
        setIsDuringBigWinLoop(false);
        // AudioApi.stop({ type: ISongs.BigWin_Intro });
        AudioApi.play({ type: ISongs.BigWin_End, stopImmediately: [ISongs.BigWin_Loop] });
      }
    });
    fadeOutAnimation.addOnSkip(() => {
      eventManager.emit(EventTypes.HANDLE_SKIP_FADE_ANIMATION);
    });
    animationChain.appendAnimation(fadeOutAnimation);
    animationChain.addOnStart(() => {
      this.winTitle.alpha = 1;
      this.winTitle.visible = true;
      this.winTitle2.alpha = 1;
      this.winTitle2.visible = true;
    });
    animationChain.addOnComplete(() => {
      this.clean();
      eventManager.emit(EventTypes.COUNT_UP_END);
      eventManager.emit(EventTypes.SET_EPIC_WIN_VISIBILITY, false);
      eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
      eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, false);
      eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, false);
      eventManager.emit(EventTypes.HANDLE_START_FADE_ANIMATION, stage);
      eventManager.emit(EventTypes.SKIP_ALL_WIN_ANIMATIONS);
    });
    animationChain.addOnSkip(() => {
      this.clean();
      eventManager.emit(EventTypes.COUNT_UP_END);
      eventManager.emit(EventTypes.HANDLE_START_FADE_ANIMATION, stage);
      eventManager.emit(EventTypes.SKIP_ALL_WIN_ANIMATIONS);
    });
    this.winCountUpAnimation = animationChain;
    return animationChain;
  }

  private clean(): void {
    setIsDuringBigWinLoop(false);
    // AudioApi.stop({ type: ISongs.BigWin_Intro });
    AudioApi.stop({ type: ISongs.BigWin_Loop });
    AudioApi.stop({ type: ISongs.BigWin_End });
    AudioApi.stop({ type: ISongs.SFX_WIN_EPIC });
    AudioApi.stop({ type: ISongs.SFX_WIN_GREAT });
    AudioApi.stop({ type: ISongs.SFX_WIN_MEGA });
    AudioApi.stop({ type: ISongs.SFX_WIN_BIG });
    AudioApi.fadeIn(1000, ISongs.BaseGameBGM_Base);
    // AudioApi.fadeIn(1000, ISongs.FreeSpinBGM_Intro);
    AudioApi.fadeIn(1000, ISongs.FreeSpinBGM_Loop);
    this.winTitle.visible = false;
    this.winTitle2.visible = false;
    this.winTitle2.scale.set(1, 1);
    this.winTitle.scale.set(1, 1);
    this.setWinValue(0);
    this.winCountUpAnimation = null;
    this.winTitle.style.fontSize = 68.78;
    this.winTitle2.style.fontSize = 68.78;
    this.updateTitlePosition();
    eventManager.emit(EventTypes.HIDE_BACKDROP);
  }

  private createBaseWinAnimation(win: number, bet: number): Animation {
    const baseWinAnimation = new AnimationGroup({});
    const duration = (win / bet / (BASE_WIN_COUNT_UP_MULTIPLIER * APPLICATION_FPS)) * 1000;
    const countUpAnimation = new Tween({
      propertyBeginValue: 0,
      target: Math.min(win, bet * BASE_WIN_AMOUNT_LIMIT),
      object: this,
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration,
    });
    const scaleXAnimation = new Tween({
      object: this.winTitle.scale,
      propertyBeginValue: 1,
      target: BASE_WIN_TITLE_SCALE,
      property: TweenProperties.X,
      duration,
    });
    const scaleYAnimation = new Tween({
      object: this.winTitle.scale,
      propertyBeginValue: 1,
      target: BASE_WIN_TITLE_SCALE,
      property: TweenProperties.Y,
      duration,
    });
    baseWinAnimation.addAnimation(scaleXAnimation);
    baseWinAnimation.addAnimation(scaleYAnimation);
    baseWinAnimation.addAnimation(countUpAnimation);
    return baseWinAnimation;
  }

  private createBigWinAnimation(win: number, bet: number, stage: WinStages): Animation {
    const bigWinAnimation = new AnimationChain();
    const countUpAnimationGroup = new AnimationGroup({});
    bigWinAnimation.addOnStart(() => {
      AudioApi.fadeOut(1000, ISongs.BaseGameBGM_Base, 0);
      AudioApi.fadeOut(1000, ISongs.BaseGameBGM_Melo, 0);
      // AudioApi.fadeOut(1000, ISongs.FreeSpinBGM_Intro, 0);
      AudioApi.fadeOut(1000, ISongs.FreeSpinBGM_Loop, 0);
      AudioApi.play({ type: ISongs.SFX_WIN_BIG, stopPrev: true });
      eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, true);
    });
    bigWinAnimation.addOnSkip(() => {
      AudioApi.stop({ type: ISongs.SFX_WIN_BIG });
      if (stage > WinStages.BigWin) {
        eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
      }
    });
    bigWinAnimation.addOnComplete(() => {
      AudioApi.stop({ type: ISongs.SFX_WIN_BIG });
      if (stage > WinStages.BigWin) {
        eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
      }
    });
    const duration =
      (Math.min(win / bet, BIG_WIN_AMOUNT_LIMIT) / (BIG_WIN_COUNT_UP_MULTIPLIER * APPLICATION_FPS)) * 1000;
    const countUpAnimation = new Tween({
      propertyBeginValue: 0,
      target: Math.min(win, bet * BIG_WIN_AMOUNT_LIMIT),
      object: this,
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration,
    });
    countUpAnimation.addOnSkip(() => this.setWinValue(Math.min(win, bet * BIG_WIN_AMOUNT_LIMIT)));
    countUpAnimationGroup.addAnimation(countUpAnimation);
    bigWinAnimation.appendAnimation(countUpAnimationGroup);
    return bigWinAnimation;
  }

  private createMegaWinAnimation(win: number, bet: number, stage: WinStages): Animation {
    const megaWinAnimation = new AnimationChain();
    const countUpAnimationGroup = new AnimationGroup({});
    megaWinAnimation.addOnStart(() => {
      AudioApi.play({ type: ISongs.SFX_WIN_MEGA, stopPrev: true });
      eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, true);
    });
    megaWinAnimation.addOnSkip(() => {
      AudioApi.stop({ type: ISongs.SFX_WIN_MEGA });
      if (stage > WinStages.MegaWin) {
        eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, false);
      }
    });
    megaWinAnimation.addOnComplete(() => {
      AudioApi.stop({ type: ISongs.SFX_WIN_MEGA });
      if (stage > WinStages.MegaWin) {
        eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, false);
      }
    });

    const duration =
      (Math.min(win / bet, MEGA_WIN_AMOUNT_LIMIT) / (MEGA_WIN_COUNT_UP_MULTIPLIER * APPLICATION_FPS)) * 1000;
    const countUpAnimation = new Tween({
      propertyBeginValue: bet * BIG_WIN_AMOUNT_LIMIT,
      target: Math.min(win, bet * MEGA_WIN_AMOUNT_LIMIT),
      object: this,
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration,
    });
    countUpAnimation.addOnSkip(() => this.setWinValue(Math.min(win, bet * MEGA_WIN_AMOUNT_LIMIT)));
    countUpAnimationGroup.addAnimation(countUpAnimation);
    megaWinAnimation.appendAnimation(countUpAnimationGroup);
    return megaWinAnimation;
  }

  private createGreatWinAnimation(win: number, bet: number, stage: WinStages): Animation {
    const greatWinAnimation = new AnimationChain();
    const countUpAnimationGroup = new AnimationGroup({});
    greatWinAnimation.addOnStart(() => {
      AudioApi.play({ type: ISongs.SFX_WIN_GREAT, stopPrev: true });
      eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, true);
    });
    greatWinAnimation.addOnSkip(() => {
      AudioApi.stop({ type: ISongs.SFX_WIN_GREAT });
      if (stage > WinStages.GreatWin) {
        eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, false);
      }
    });
    greatWinAnimation.addOnComplete(() => {
      AudioApi.stop({ type: ISongs.SFX_WIN_GREAT });
      if (stage > WinStages.GreatWin) {
        eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, false);
      }
    });
    const duration =
      (Math.min(win / bet, GREAT_WIN_AMOUNT_LIMIT) / (GREAT_WIN_COUNT_UP_MULTIPLIER * APPLICATION_FPS)) * 1000;
    const countUpAnimation = new Tween({
      propertyBeginValue: bet * MEGA_WIN_AMOUNT_LIMIT,
      target: Math.min(win, bet * GREAT_WIN_AMOUNT_LIMIT),
      object: this,
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration,
    });
    countUpAnimation.addOnSkip(() => this.setWinValue(Math.min(win, bet * GREAT_WIN_AMOUNT_LIMIT)));
    countUpAnimationGroup.addAnimation(countUpAnimation);
    greatWinAnimation.appendAnimation(countUpAnimationGroup);
    return greatWinAnimation;
  }

  private createEpicWinAnimation(win: number, bet: number): Animation {
    const epicWinAnimation = new AnimationChain();
    const countUpAnimationGroup = new AnimationGroup({});
    epicWinAnimation.addOnStart(() => {
      eventManager.emit(EventTypes.SET_EPIC_WIN_VISIBILITY, true);
      AudioApi.play({ type: ISongs.SFX_WIN_EPIC });
    });
    epicWinAnimation.addOnComplete(() => {
      AudioApi.stop({ type: ISongs.SFX_WIN_EPIC });
    });
    epicWinAnimation.addOnSkip(() => {
      AudioApi.stop({ type: ISongs.SFX_WIN_EPIC });
      this.setWinValue(win);
    });
    const duration = (win / bet / (EPIC_WIN_COUNT_UP_MULTIPLIER * APPLICATION_FPS)) * 1000;
    const countUpAnimation = new Tween({
      propertyBeginValue: bet * GREAT_WIN_AMOUNT_LIMIT,
      target: win,
      object: this,
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration,
    });
    countUpAnimationGroup.addAnimation(countUpAnimation);
    epicWinAnimation.appendAnimation(countUpAnimationGroup);

    return epicWinAnimation;
  }

  private countStage(bet: number, win: number): WinStages {
    const multiplier = win / bet;

    if (multiplier < DOUBLE_WIN_AMOUNT_LIMIT) {
      return WinStages.None;
    }
    if (multiplier >= DOUBLE_WIN_AMOUNT_LIMIT && multiplier < BASE_WIN_AMOUNT_LIMIT) {
      return WinStages.BaseWin;
    }
    if (multiplier >= BASE_WIN_AMOUNT_LIMIT && multiplier < BIG_WIN_AMOUNT_LIMIT) {
      return WinStages.BigWin;
    }
    if (multiplier >= BIG_WIN_AMOUNT_LIMIT && multiplier < MEGA_WIN_AMOUNT_LIMIT) return WinStages.MegaWin;
    if (multiplier >= MEGA_WIN_AMOUNT_LIMIT && multiplier < GREAT_WIN_AMOUNT_LIMIT) return WinStages.GreatWin;
    return WinStages.EpicWin;
  }

  public setWinValue(winValue: number): void {
    this.winValue = winValue < 0 ? 0 : winValue;
    this.winTitle.text = `${formatNumber({
      currency: setCurrency(),
      value: winValue,
      showCurrency: showCurrency(setCurrency()),
    })}`;
    this.winTitle2.text = `${formatNumber({
      currency: setCurrency(),
      value: winValue,
      showCurrency: showCurrency(setCurrency()),
    })}`;
  }

  private updateTitlePosition(): void {
    this.winTitle.x = 0;
    this.winTitle.y = SLOTS_CONTAINER_HEIGHT - 300;

    if (isMobilePortrait(this.screenWidth, this.screenHeight)) {
      this.winTitle.y = SLOTS_CONTAINER_HEIGHT - 125;
    }

    if (setIsDuringBigWinLoop()) {
      this.winTitle.y = SLOT_HEIGHT - 80;
    }
  }

  private resize(width: number, height: number): void {
    this.screenWidth = width;
    this.screenHeight = height;
    this.updateTitlePosition();
  }
}

export default WinCountUpMessage;
