import i18n from 'i18next';
import _ from 'lodash';
import * as PIXI from 'pixi.js';

import { ELoaderStages, ILoaderResource } from '@phoenix7dev/shared-components/dist/loader/d';
import type { IResource } from '@phoenix7dev/shared-components/dist/resources/d';

import { config } from '../config';
import { BonusStatus, EventTypes, UserBonus } from '../global.d';
import { client, getUserBonuses, slotBetGql, slotHistoryGql } from '../gql';
import {
  setBetAmount,
  setCoinAmount,
  setCoinValue,
  setCurrency,
  setSlotConfig,
  setStressful,
  setUserLastBetResult,
} from '../gql/cache';
import type { IBet, ISlotHistoryData } from '../gql/d';
import { eventManager } from '../slotMachine/config';
import type { Features } from '../slotMachine/d';

import type { IPixiAssets } from './d';

export const wait = (ms: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const pixiLoad = (): Promise<Partial<Record<string, PIXI.LoaderResource>>> => {
  return new Promise((resolve, reject) => {
    PIXI.Loader.shared.load((_loader, resources) => {
      const failed = _.filter(resources, (resource) => !!resource?.error);
      if (failed.length) return reject(failed);
      return resolve(resources);
    });
    PIXI.Loader.shared.onError.once(() => {
      return reject();
    });
  });
};

export const loadPixiAssets = (assets: IPixiAssets[], baseUrl: string): Promise<void> => {
  PIXI.Loader.shared.baseUrl = baseUrl;
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    assets.forEach((asset) => PIXI.Loader.shared.add(asset.name, asset.src));
    let tries = config.failureRetries;
    let success = false;

    while (tries > 0) {
      try {
        tries -= 1;
        await pixiLoad();
        success = true;
        break;
      } catch (err) {
        console.error(err);
      }
    }

    return success ? resolve() : reject();
  });
};

export const loadImages = async (
  assets: IterableIterator<[string, IResource]>,
  cb?: CallableFunction,
): Promise<void> => {
  let promises: Promise<IResource>[] = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const [, value] of assets) {
    promises.push(
      new Promise((resolve, reject) => {
        const asset: HTMLImageElement = new Image();
        asset.src = value.source;
        asset.onload = () => {
          if (cb) cb(value.key);
          resolve(value);
        };
        asset.onerror = () => reject(value);
      }),
    );
  }

  let tries = config.failureRetries;
  let success = false;

  while (tries > 0) {
    try {
      tries -= 1;
      const result: PromiseRejectedResult | PromiseFulfilledResult<IResource>[] = (await Promise.allSettled(
        promises,
      )) as PromiseRejectedResult | PromiseFulfilledResult<IResource>[];
      const failed = _.filter(result, (asset) => asset['status'] === 'rejected') as PromiseRejectedResult[];

      if (failed.length) {
        promises = failed.map((rejected) => {
          return new Promise((resolve, reject) => {
            const asset: HTMLImageElement = new Image();
            asset.src = rejected.reason.source;
            asset.onload = () => {
              if (cb) cb(rejected.reason.key);
              resolve(rejected.reason);
            };
            asset.onerror = () => reject(rejected.reason);
          });
        });
        continue;
      }
      success = true;
      break;
    } catch (err) {
      console.error(err);
    }
  }

  return success ? Promise.resolve() : Promise.reject();
};

export const isDevelopment = (): boolean => process.env.NODE_ENV === 'development';

export const getCssVariable = (cssVar: string): string => {
  return getComputedStyle(document.documentElement).getPropertyValue(cssVar);
};
export const calcBottomContainerHeight = (width: number, height: number): number => {
  if (width < height) {
    return height * (parseInt(getCssVariable('--bottom-height-percent-portrait'), 10) / 100);
  }
  return height * (parseInt(getCssVariable('--bottom-height-percent-landscape'), 10) / 100);
};

export const isBuyFeatureEnabled = (features: Features[] = []): boolean => {
  const freeSpinFeature = features.find((i) => i.id === 'freeSpins');

  return freeSpinFeature?.enabled || false;
};

export const normalizeBalance = (balance = 0): number => {
  return balance / 100;
};

export const normalizeCoins = (coins = 0, coinValue = setCoinValue()): number => {
  return (coins * coinValue) / 100;
};
export const showCurrency = (currency: string): boolean => {
  return currency !== 'FUN';
};

export const updateTextScale = (text: PIXI.Text, maxWidth: number, maxHeight: number): void => {
  text.scale.set(1, 1);
  text.updateText(true);
  const ratio = Math.min(1, Math.min(maxWidth / text.width, maxHeight / text.height));
  text.scale.set(ratio, ratio);
};

export const getUserActiveBonuses = () => {
  return client.query<{ userBonuses: UserBonus[] }>({
    query: getUserBonuses,
    variables: {
      input: { status: BonusStatus.ACTIVE, slotId: setSlotConfig().id },
    },
    fetchPolicy: 'network-only',
  });
};

export const getLastBet = async (isLoadScreen: boolean) => {
  const betsData = await client.query<{ bets: ISlotHistoryData }>({
    query: slotHistoryGql,
    variables: {
      input: { last: 1, filter: { slotId: setSlotConfig().id } },
    },
    fetchPolicy: 'network-only',
  });
  const lastBet = betsData.data.bets.edges[0];
  if (lastBet) {
    if (lastBet.node.userBonus?.data?.betId && !isLoadScreen) {
      const bet = await client.query<{ bet: IBet }>({
        query: slotBetGql,
        variables: { input: { id: lastBet.node.userBonus?.data?.betId } },
        fetchPolicy: 'network-only',
      });
      setUserLastBetResult(bet.data.bet);
    } else {
      setUserLastBetResult(lastBet.node);
    }
  }
};
export const loadErrorHandler = (error?: Error, resources?: ILoaderResource[]): void => {
  const stage = resources?.find((r) => !!r.error);
  const errorMsg = stage?.error as unknown as string;
  switch (stage?.name) {
    case ELoaderStages.AUTH:
      setStressful({
        show: true,
        type: 'network',
        message:
          (i18n.t(['errors.CLIENT.INVALID_CLIENT_TOKEN', 'errors.UNKNOWN.UNKNOWN']) as string) ||
          (error as unknown as string),
      });
      break;
    default:
      setStressful({
        show: true,
        type: 'network',
        message:
          (i18n.t([errorMsg === 'Failed to fetch' ? 'errors.UNKNOWN.NETWORK' : 'errors.UNKNOWN.UNKNOWN']) as string) ||
          (error as unknown as string),
      });
  }
};

export const findSubstituteCoinAmount = (requestedCoinAmount: number, coinAmounts: number[]): number => {
  for (let i = coinAmounts.length - 1; i >= 0; i--) {
    const coinAmount = coinAmounts[i]!;

    if (coinAmount <= requestedCoinAmount) {
      return coinAmount;
    }
  }

  return coinAmounts[0] ?? 0; // return 0 if BE send empty arr []
};

// updated coin value from BE after bonus game, because on bonus game we use Coin Value from history
export const updateCoinValueAfterBonuses = (): void => {
  const coinValue = setSlotConfig().clientSettings.coinValues.find((elem) => elem.code === setCurrency())?.variants[0];
  const coinAmount = findSubstituteCoinAmount(setCoinAmount(), setSlotConfig().clientSettings.coinAmounts.default);
  setCoinValue(coinValue);
  setCoinAmount(coinAmount);
  setBetAmount(coinAmount * setSlotConfig().lineSets[0]!.coinAmountMultiplier);
  eventManager.emit(EventTypes.UPDATE_BET);
};

export const queryParams = new URLSearchParams(window.location.search);
