import * as PIXI from 'pixi.js';
import { TextStyle } from 'pixi.js';

import { Colors, Variables } from '../../config/variables';

const winCounterStyle = new TextStyle({
  align: 'center',
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 68.78,
  fontWeight: '700',
  lineJoin: 'round',
  miterLimit: 0,
  stroke: '#fff',
  strokeThickness: 22,
  whiteSpace: 'normal',
  lineHeight: 39,
});

const winCounterStyle2 = new TextStyle({
  align: 'center',
  fill: ['#fff27c', '#ffe388', '#ff9900', '#ffee54'],
  fillGradientStops: [0, 0.45, 0.55, 0.75],
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 68.78,
  fontWeight: '700',
  lineJoin: 'round',
  miterLimit: 0,
  strokeThickness: 14,
  whiteSpace: 'normal',
  lineHeight: 39,
});

export const winValueStyles = new PIXI.TextStyle(winCounterStyle);
export const winValueStyles2 = new TextStyle(winCounterStyle2);

// BuyFeature Button Text
export const FEATURE_BTN_TEXT_FIT_TO_WIDTH = 90;
export const buyFeatureTextStyle = new PIXI.TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  align: 'center',
  fontSize: 32,
  fill: '#ffd74a',
  fontWeight: '900',
  stroke: '#631800',
  strokeThickness: 4,
  lineHeight: 39,
});

export const autoPlayCounterTextStyle = new TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  fontWeight: 'bold',
  stroke: 'black',
  strokeThickness: 4,
  fontSize: 25,
  whiteSpace: 'normal',
  align: 'center',
  fill: Colors.GAME_COLOR,
});

export const soundToastStyles = {
  default: {
    fontFamily: Variables.FONT_FAMILY,
    fontSize: 40,
    fill: '#ffffff',
    stroke: 'black',
    strokeThickness: 5,
  },
  color: {
    fill: Colors.GAME_COLOR,
  },
};

export const clockTextStyle = new TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  fontWeight: 'bold',
  fontSize: 26,
  whiteSpace: 'normal',
  align: 'center',
  fill: 0xffffff,
  trim: true,
});

export const gameNameTextStyle = new TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  fontWeight: 'bold',
  fontSize: 26,
  whiteSpace: 'normal',
  align: 'center',
  fill: 0xffffff,
  trim: true,
});
export const bottomContainerTextStyle = {
  default: {
    fontFamily: Variables.FONT_FAMILY,
    fill: '#ffffff',
    fontSize: 26,
    lineHeight: 26,
    trim: true,
  },
  color: {
    fill: Colors.GAME_COLOR,
  },
};

export const miniPayTableStyle = {
  fontSize: 28,
  lineHeight: 50,
  fontFamily: Variables.FONT_FAMILY,
  whiteSpace: 'normal',
  fill: '#ffffff',
  stroke: '#000000',
  strokeThickness: 5,
  lineJoin: 'round',
  trim: true,
};

export const miniPayTableAdditionalTextStyle = {
  fontSize: 28,
  lineHeight: 30,
  fontFamily: Variables.FONT_FAMILY,
  whiteSpace: 'normal',
  fill: '#ffffff',
  stroke: '#000000',
  strokeThickness: 5,
  lineJoin: 'round',
  wordWrap: true,
  wordWrapWidth: 220,
  align: 'center',
};
