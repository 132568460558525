export const gameRules = [
  { key: 'infoGameRules1_1', buyFeatureOnly: false },
  { key: 'infoGameRules1_2', buyFeatureOnly: false },
  { key: 'infoGameRules1_3', buyFeatureOnly: false },
  { key: 'infoGameRules1_4', buyFeatureOnly: false },
  { key: 'infoGameRules1_5', buyFeatureOnly: false },
  { key: 'infoGameRules1_6', buyFeatureOnly: false },
  { key: 'infoGameRules1_7', buyFeatureOnly: false },
  { key: 'infoGameRules1_8', buyFeatureOnly: false },
  { key: 'infoGameRules1_9', buyFeatureOnly: false },
];

export const infoFeatureFreeSpinDescs = [
  'infoFeatureFreeSpinDesc_1',
  'infoFeatureFreeSpinDesc_2',
  'infoFeatureFreeSpinDesc_3',
  'infoFeatureFreeSpinDesc_4',
  'infoFeatureFreeSpinDesc_5',
];

export const infoFeaturePickEmBonusDescs = [
  'infoFeaturePickEmBonusDesc_1',
  'infoFeaturePickEmBonusDesc_2',
  'infoFeaturePickEmBonusDesc_3',
  'infoFeaturePickEmBonusDesc_4',
  'infoFeaturePickEmBonusDesc_5',
];

export const infoFeatureMultiplierWildsDescs = [
  'infoFeatureMultiplierWildsDesc_1',
  'infoFeatureMultiplierWildsDesc_2',
  'infoFeatureMultiplierWildsDesc_3',
  'infoFeatureMultiplierWildsDesc_4',
  'infoFeatureMultiplierWildsDesc_5',
];

export const infoFeatureBuyDescs = [
  'infoFeatureBuyDesc_1',
  'infoFeatureBuyDesc_2',
  'infoFeatureBuyDesc_3',
  'infoFeatureBuyDesc_4',
  'infoFeatureBuyDesc_5',
];
