import { TextStyle } from 'pixi.js';

import { Variables } from '../../config/variables';
import { ResourceTypes } from '../../resources.d';

export const pickemConfig: { [key in number]: ResourceTypes } = {
  0: ResourceTypes.hive1,
  1: ResourceTypes.hive4,
  2: ResourceTypes.hive2,
  3: ResourceTypes.hive3,
  4: ResourceTypes.hive1,
  5: ResourceTypes.hive1,
  6: ResourceTypes.hive2,
  7: ResourceTypes.hive3,
  8: ResourceTypes.hive1,
  9: ResourceTypes.hive4,
  10: ResourceTypes.hive3,
  11: ResourceTypes.hive2,
  12: ResourceTypes.hive1,
  13: ResourceTypes.hive2,
  14: ResourceTypes.hive4,
};

export const pickemTitle = new TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  fill: '#fff0bb',
  fontSize: 42,
  fontWeight: '900',
  stroke: '#4e2500',
  strokeThickness: 6,
  lineHeight: 68,
  miterLimit: 5,
  padding: 5,
  whiteSpace: 'normal',
  wordWrapWidth: 140,
  align: 'center',
});

export const pickemValue = new TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  fill: ['#FFF93F', '#FFC400'],
  fontSize: 64,
  fontWeight: '900',
  stroke: '#4e2500',
  strokeThickness: 6,
  lineHeight: 68,
  miterLimit: 5,
  padding: 5,
  whiteSpace: 'normal',
  wordWrapWidth: 140,
  align: 'center',
});
