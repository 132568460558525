import { ResourceTypes } from '../resources.d';
import type { IPixiAssets } from '../utils/d';
import Resources from '../utils/resources';

import { SlotId } from './config';

export const MAPPED_SYMBOLS = {
  [SlotId.A]: ResourceTypes.top1,
  [SlotId.B]: ResourceTypes.top2,
  [SlotId.C]: ResourceTypes.top3,
  [SlotId.D]: ResourceTypes.top4,
  [SlotId.E]: ResourceTypes.middle1,
  [SlotId.F]: ResourceTypes.middle2,
  [SlotId.G]: ResourceTypes.lowA,
  [SlotId.H]: ResourceTypes.lowK,
  [SlotId.I]: ResourceTypes.lowQ,
  [SlotId.J]: ResourceTypes.lowJ,
  [SlotId.K]: ResourceTypes.low10,
  [SlotId.WL]: ResourceTypes.wild,
  [SlotId.SC1]: ResourceTypes.scatter,
};

export const MAPPED_BLURRED_SYMBOLS = {
  [SlotId.A]: ResourceTypes.blurTop1,
  [SlotId.B]: ResourceTypes.blurTop2,
  [SlotId.C]: ResourceTypes.blurTop3,
  [SlotId.D]: ResourceTypes.blurTop4,
  [SlotId.E]: ResourceTypes.blurMiddle1,
  [SlotId.F]: ResourceTypes.blurMiddle2,
  [SlotId.G]: ResourceTypes.blurLowA,
  [SlotId.H]: ResourceTypes.blurLowK,
  [SlotId.I]: ResourceTypes.blurLowQ,
  [SlotId.J]: ResourceTypes.blurLowJ,
  [SlotId.K]: ResourceTypes.blurLow10,
  [SlotId.WL]: ResourceTypes.wild,
  [SlotId.SC1]: ResourceTypes.scatter,
};

export enum SymbolAnimationType {
  DEFAULT,
  SPRITE,
  SPINE,
}

export const MAPPED_SYMBOLS_WIN_ANIMATIONS: {
  [key in SlotId]: {
    type: SymbolAnimationType;
    src?: string;
    animation?: string;
  };
} = {
  [SlotId.A]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'top_1',
  },
  [SlotId.B]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'top_2',
  },
  [SlotId.C]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'top_3',
  },
  [SlotId.D]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'top_4',
  },
  [SlotId.E]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'middle_1',
  },
  [SlotId.F]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'middle_2',
  },
  [SlotId.G]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'low_a',
  },
  [SlotId.H]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'low_k',
  },
  [SlotId.I]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'low_q',
  },
  [SlotId.J]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'low_j',
  },
  [SlotId.K]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'low_10',
  },
  [SlotId.SC1]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'scatter_win',
  },
  [SlotId.WL]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'wild_win_x2',
  },
};

export const LOADER_MAPPED_SYMBOLS = [
  {
    name: ResourceTypes.top1,
    src: Resources.getSource(ResourceTypes.top1),
  },
  {
    name: ResourceTypes.top2,
    src: Resources.getSource(ResourceTypes.top2),
  },
  {
    name: ResourceTypes.top3,
    src: Resources.getSource(ResourceTypes.top3),
  },
  { name: ResourceTypes.top4, src: Resources.getSource(ResourceTypes.top4) },
  {
    name: ResourceTypes.middle1,
    src: Resources.getSource(ResourceTypes.middle1),
  },
  {
    name: ResourceTypes.middle2,
    src: Resources.getSource(ResourceTypes.middle2),
  },
  {
    name: ResourceTypes.lowA,
    src: Resources.getSource(ResourceTypes.lowA),
  },
  {
    name: ResourceTypes.lowK,
    src: Resources.getSource(ResourceTypes.lowK),
  },
  {
    name: ResourceTypes.lowQ,
    src: Resources.getSource(ResourceTypes.lowQ),
  },
  {
    name: ResourceTypes.lowJ,
    src: Resources.getSource(ResourceTypes.lowJ),
  },
  {
    name: ResourceTypes.low10,
    src: Resources.getSource(ResourceTypes.low10),
  },
  { name: ResourceTypes.wild, src: Resources.getSource(ResourceTypes.wild) },
  {
    name: ResourceTypes.scatter,
    src: Resources.getSource(ResourceTypes.scatter),
  },
];

export const LOADER_TEXTURES = [
  { name: ResourceTypes.frame, src: Resources.getSource(ResourceTypes.frame) },
  {
    name: ResourceTypes.pickemFrame,
    src: Resources.getSource(ResourceTypes.pickemFrame),
  },
  {
    name: ResourceTypes.pickemBg,
    src: Resources.getSource(ResourceTypes.pickemBg),
  },
  {
    name: ResourceTypes.hive1,
    src: Resources.getSource(ResourceTypes.hive1),
  },
  {
    name: ResourceTypes.hive2,
    src: Resources.getSource(ResourceTypes.hive2),
  },
  {
    name: ResourceTypes.hive3,
    src: Resources.getSource(ResourceTypes.hive3),
  },
  {
    name: ResourceTypes.hive4,
    src: Resources.getSource(ResourceTypes.hive4),
  },
  {
    name: ResourceTypes.patron,
    src: Resources.getSource(ResourceTypes.patron),
  },
  {
    name: ResourceTypes.honeycomb,
    src: Resources.getSource(ResourceTypes.honeycomb),
  },
  {
    name: ResourceTypes.slotBg,
    src: Resources.getSource(ResourceTypes.slotBg),
  },
  {
    name: ResourceTypes.introBg,
    src: Resources.getSource(ResourceTypes.introBg),
  },
  {
    name: ResourceTypes.introReel1,
    src: Resources.getSource(ResourceTypes.introReel1),
  },
  {
    name: ResourceTypes.introReel2,
    src: Resources.getSource(ResourceTypes.introReel2),
  },
  {
    name: ResourceTypes.introReel3,
    src: Resources.getSource(ResourceTypes.introReel3),
  },
  {
    name: ResourceTypes.slotFreeSpinBg,
    src: Resources.getSource(ResourceTypes.slotFreeSpinBg),
  },
  {
    name: ResourceTypes.buyFeatureBtn,
    src: Resources.getSource(ResourceTypes.buyFeatureBtn),
  },
  {
    name: ResourceTypes.buyFeatureBtnNotActive,
    src: Resources.getSource(ResourceTypes.buyFeatureBtnNotActive),
  },
  {
    name: ResourceTypes.buyFeatureBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeatureBtnHover),
  },
  {
    name: ResourceTypes.buyFeatureBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeatureBtnPressed),
  },
  {
    name: ResourceTypes.buyFeatureBtnMobile,
    src: Resources.getSource(ResourceTypes.buyFeatureBtnMobile),
  },
  {
    name: ResourceTypes.buyFeatureBtnNotActiveMobile,
    src: Resources.getSource(ResourceTypes.buyFeatureBtnNotActiveMobile),
  },
  {
    name: ResourceTypes.buyFeatureBtnHoverMobile,
    src: Resources.getSource(ResourceTypes.buyFeatureBtnHoverMobile),
  },
  {
    name: ResourceTypes.buyFeatureBee,
    src: Resources.getSource(ResourceTypes.buyFeatureBee),
  },
  {
    name: ResourceTypes.buyFeatureBtnPressedMobile,
    src: Resources.getSource(ResourceTypes.buyFeatureBtnPressedMobile),
  },
  {
    name: ResourceTypes.buyFeatureInput,
    src: Resources.getSource(ResourceTypes.buyFeatureInput),
  },
  {
    name: ResourceTypes.buyFeatureMinusBtn,
    src: Resources.getSource(ResourceTypes.buyFeatureMinusBtn),
  },
  {
    name: ResourceTypes.buyFeatureMinusBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeatureMinusBtnHover),
  },
  {
    name: ResourceTypes.buyFeatureMinusBtnDisabled,
    src: Resources.getSource(ResourceTypes.buyFeatureMinusBtnDisabled),
  },
  {
    name: ResourceTypes.buyFeatureMinusBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeatureMinusBtnPressed),
  },
  {
    name: ResourceTypes.buyFeaturePlusBtn,
    src: Resources.getSource(ResourceTypes.buyFeaturePlusBtn),
  },
  {
    name: ResourceTypes.buyFeaturePlusBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeaturePlusBtnHover),
  },
  {
    name: ResourceTypes.buyFeaturePlusBtnDisabled,
    src: Resources.getSource(ResourceTypes.buyFeaturePlusBtnDisabled),
  },
  {
    name: ResourceTypes.buyFeaturePlusBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeaturePlusBtnPressed),
  },
  {
    name: ResourceTypes.buyFeatureOkBtn,
    src: Resources.getSource(ResourceTypes.buyFeatureOkBtn),
  },
  {
    name: ResourceTypes.buyFeatureOkBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeatureOkBtnHover),
  },
  {
    name: ResourceTypes.buyFeatureOkBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeatureOkBtnPressed),
  },
  {
    name: ResourceTypes.buyFeatureOkBtnDisabled,
    src: Resources.getSource(ResourceTypes.buyFeatureOkBtnDisabled),
  },
  {
    name: ResourceTypes.buyFeatureCancelBtn,
    src: Resources.getSource(ResourceTypes.buyFeatureCancelBtn),
  },
  {
    name: ResourceTypes.buyFeatureCancelBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeatureCancelBtnHover),
  },
  {
    name: ResourceTypes.buyFeatureCancelBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeatureCancelBtnPressed),
  },
  {
    name: ResourceTypes.buyFeaturePopup,
    src: Resources.getSource(ResourceTypes.buyFeaturePopup),
  },
  {
    name: ResourceTypes.backdrop,
    src: Resources.getSource(ResourceTypes.backdrop),
  },
  {
    name: ResourceTypes.blurTop1,
    src: Resources.getSource(ResourceTypes.blurTop1),
  },
  {
    name: ResourceTypes.blurTop2,
    src: Resources.getSource(ResourceTypes.blurTop2),
  },
  {
    name: ResourceTypes.blurTop3,
    src: Resources.getSource(ResourceTypes.blurTop3),
  },
  {
    name: ResourceTypes.blurTop4,
    src: Resources.getSource(ResourceTypes.blurTop4),
  },
  {
    name: ResourceTypes.blurMiddle1,
    src: Resources.getSource(ResourceTypes.blurMiddle1),
  },
  {
    name: ResourceTypes.blurMiddle2,
    src: Resources.getSource(ResourceTypes.blurMiddle2),
  },
  {
    name: ResourceTypes.blurLowA,
    src: Resources.getSource(ResourceTypes.blurLowA),
  },
  {
    name: ResourceTypes.blurLowK,
    src: Resources.getSource(ResourceTypes.blurLowK),
  },
  {
    name: ResourceTypes.blurLowQ,
    src: Resources.getSource(ResourceTypes.blurLowQ),
  },
  {
    name: ResourceTypes.blurLowJ,
    src: Resources.getSource(ResourceTypes.blurLowJ),
  },
  {
    name: ResourceTypes.blurLow10,
    src: Resources.getSource(ResourceTypes.blurLow10),
  },
  {
    name: ResourceTypes.gameLogo,
    src: Resources.getSource(ResourceTypes.gameLogo),
  },
  {
    name: 'ui',
    src: '/images/ui/ui.json',
  },
];

export const SPINE_LOADER_TEXTURES = (_isMobile: boolean): IPixiAssets[] => {
  return [
    {
      name: 'ambient',
      src: '/animations/ambient/ambient.json',
      isSpine: true,
      spineImages: ['ambient.png', 'ambient1.jpg', 'ambient0.png'],
    },
    {
      name: 'win_messages',
      src: '/animations/win_messages/special_messages.json',
      isSpine: true,
      spineImages: ['special_messages.png', 'special_messages1.jpg'],
    },
    {
      name: 'paylines',
      src: '/animations/paylines/line.json',
      isSpine: true,
      spineImages: ['line.png'],
    },
    {
      name: 'symbols',
      src: '/animations/symbol_win/symbols.json',
      isSpine: true,
      spineImages: ['symbols.png', 'symbols1.jpg'],
    },
    {
      name: 'anticipation',
      src: '/animations/anticipation/anticipation.json',
      isSpine: true,
      spineImages: ['anticipation.jpg'],
    },
    {
      name: 'pickem_bonus',
      src: '/animations/pickem/pickem_bonus.json',
      isSpine: true,
      spineImages: ['pickem_bonus.png'],
    },
    {
      name: 'popup',
      src: '/animations/popup/pop_ups.json',
      isSpine: true,
      spineImages: ['pop_ups.png'],
    },
    {
      name: 'phoenixAnticipation',
      src: '/movie/phoenixAnticipation.mp4',
    },
  ];
};
