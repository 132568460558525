import type { SlotId } from '../../config';
import { EventTypes, ReelSet } from '../../global.d';
import ViewContainer from '../components/container';
import {
  ANTICIPATION_DURATION,
  ANTICIPATION_REEL_ENDING_SLOTS_AMOUNT,
  BASE_SPIN_TIME,
  eventManager,
  FORCE_STOP_SPIN_ANIMATION_DURATION,
  FORCE_STOP_SPIN_PER_EACH_DURATION,
  INIT_SLOTS_AMOUNT_SPIN_BEFORE_STOP,
  REEL_ENDING_SLOTS_AMOUNT,
  REELS_AMOUNT,
  ReelState,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  TURBO_SPIN_TIME,
} from '../config';
import Reel from './reel';

class ReelsContainer extends ViewContainer {
  public reels: Reel[] = [];

  constructor(reels: SlotId[][], startPosition: number[]) {
    super();
    this.initContainer();
    this.initReels(reels, startPosition);
    eventManager.addListener(
      EventTypes.SET_SLOTS_VISIBILITY,
      this.setSlotsVisibility.bind(this),
    );
    eventManager.addListener(
      EventTypes.SETUP_REEL_POSITIONS,
      this.setupAnimationTarget.bind(this),
    );
    eventManager.addListener(
      EventTypes.FORCE_STOP_REELS,
      this.forceStopReels.bind(this),
    );
    eventManager.addListener(
      EventTypes.CHANGE_REEL_SET,
      this.changeReelSet.bind(this),
    );
    eventManager.addListener(
      EventTypes.ROLLBACK_REELS,
      this.rollbackReels.bind(this),
    );
    this.sortableChildren = true;
  }

  private rollbackReels(positions: number[]): void {
    for (let i = 0; i < positions.length; i++) {
      eventManager.emit(
        EventTypes.REMOVE_TWEEN_ANIMATION,
        this.reels[i]!.spinAnimation?.getStarting(),
      );
      eventManager.emit(
        EventTypes.REMOVE_TWEEN_ANIMATION,
        this.reels[i]!.spinAnimation?.getFakeRolling(),
      );
      this.reels[i]!.position = this.reels[i]!.size - positions[i]!;
      this.reels[i]!.state = ReelState.IDLE;
    }
  }

  private initContainer(): void {
    this.width = SLOTS_CONTAINER_WIDTH;
    this.height = SLOTS_CONTAINER_HEIGHT;
  }

  private changeReelSet(settings: {
    reelSet: ReelSet;
    reelPositions: number[];
  }): void {
    const reelPositions = settings.reelPositions
      .slice(0, 5)
      .map(
        (position, idx) =>
          (settings.reelSet.layout[idx]!.length - position) %
          settings.reelSet.layout[idx]!.length,
      );

    for (let i = 0; i < REELS_AMOUNT; i++) {
      this.reels[i]!.clean();
      this.reels[i]!.init(settings.reelSet.layout[i]!, reelPositions[i]!);
    }
  }

  private initReels(reels: SlotId[][], startPosition?: number[]): void {
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const position = startPosition ? startPosition[i] : 0;
      const reel = new Reel(i, reels[i]!, position!);
      reel.container.zIndex = REELS_AMOUNT - i;
      this.reels[i] = reel;
      this.addChild(reel.container);

      eventManager.emit(EventTypes.REGISTER_ANIMATOR, reel.animator);
    }
  }

  private forceStopReels(isTurboSpin: boolean): void {
    const stopAllReelsAtSameTime =
      Date.now() - this.reels[0]!.spinAnimation!.startTime <
      (isTurboSpin ? TURBO_SPIN_TIME : BASE_SPIN_TIME);
    for (let i = 0; i < this.reels.length; i++) {
      if (stopAllReelsAtSameTime && i !== 0) {
        this.reels[i]!.isPlaySoundOnStop = false;
      }
      this.reels[i]!.stopReel(
        stopAllReelsAtSameTime
          ? FORCE_STOP_SPIN_ANIMATION_DURATION
          : FORCE_STOP_SPIN_ANIMATION_DURATION +
              i * FORCE_STOP_SPIN_PER_EACH_DURATION,
      );
    }
  }

  private prolongTarget = (reel: Reel, minValue: number): number => {
    let res = 0;
    while (res < minValue) res += reel.data.length;
    return res;
  };

  private setupAnimationTarget(
    reelPositions: number[],
    scatterNo: number[],
    anticipationReelId: number,
  ): void {
    for (let j = 0; j < this.reels.length; j++) {
      const reel = this.reels[j]!;
      const fakeRollingAnimation =
        reel.spinAnimation!.getFakeRolling();
      const rollingAnimation = reel.spinAnimation!.getRolling();
      const endingAnimation = reel.spinAnimation!.getEnding();
      let target = reel.getTarget(
        reel.data.length - reelPositions[j]!,
      );
      fakeRollingAnimation.duration = 0;
      reel['scatter_no'] = scatterNo[j]!;
      if (j > anticipationReelId) {
        let beginValue =
          target -
          INIT_SLOTS_AMOUNT_SPIN_BEFORE_STOP -
          ANTICIPATION_REEL_ENDING_SLOTS_AMOUNT -
          j * 5 -
          (j - anticipationReelId - 1) * (reel.isTurboSpin ? 150 : 55);
        if (beginValue < 0) {
          const prolong = this.prolongTarget(
            reel,
            Math.abs(beginValue),
          );
          beginValue += prolong;
          target += prolong;
        }
        rollingAnimation.propertyBeginValue = beginValue;

        rollingAnimation.target =
          target - ANTICIPATION_REEL_ENDING_SLOTS_AMOUNT;
        rollingAnimation.duration +=
          ANTICIPATION_DURATION * (j - anticipationReelId - 1);

        endingAnimation.propertyBeginValue =
          target - ANTICIPATION_REEL_ENDING_SLOTS_AMOUNT;
        endingAnimation.target = target;
        endingAnimation.duration = ANTICIPATION_DURATION;
        endingAnimation.addOnStart(() =>
          eventManager.emit(EventTypes.ANTICIPATION_STARTS, j),
        );
      } else {
        rollingAnimation.propertyBeginValue =
          target -
          INIT_SLOTS_AMOUNT_SPIN_BEFORE_STOP -
          REEL_ENDING_SLOTS_AMOUNT -
          j * 5;
        rollingAnimation.target = target - REEL_ENDING_SLOTS_AMOUNT;
        endingAnimation.propertyBeginValue = target - REEL_ENDING_SLOTS_AMOUNT;
        endingAnimation.target = target;
      }
    }
  }

  private setSlotsVisibility(slots: number[], visibility: boolean): void {
    slots.forEach((slotId) => {
      const x = slotId % REELS_AMOUNT;
      const y = Math.floor(slotId / REELS_AMOUNT);
      const reel = this.reels[x]!;
      const position =
        reel.size -
        (Math.round(reel.position) % reel.size) +
        y -
        1;
      const normalizedPosition =
        position === -1
          ? reel.size - 1
          : position % reel.size;
      const slot = reel.slots[normalizedPosition];
      if (slot) slot.visible = visibility;
    });
  }
}

export default ReelsContainer;
