import { ResourceTypes } from '../resources.d';

export const config = {
  failureRetries: 3,
  autoplay: {
    stopOnWinExceedsMinValue: 0,
    stopOnBalanceIncreaseMinValue: 0,
    stopOnBalanceDecreaseMinValue: 0,
    stopOnWinExceedsMaxValue: 500,
    stopOnBalanceIncreaseMaxValue: 5000,
    stopOnBalanceDecreaseMaxValue: 1000,
    defaultValues: {
      isStopOnAnyWin: false,
      isStopOnWinExceeds: false,
      isStopOnBalanceIncrease: false,
      isStopOnBalanceDecrease: false,
      singleWinValue: 25,
      increaseValue: 25,
      decreaseValue: 25,
    },
    autoSpinValues: [25, 50, 75, 100, 500],
    timeOut: 1000,
  },
  betSettings: {
    mockedBets: [25, 50, 75, 100],
    minBet: 25,
  },
  historyTable: {
    showBy: 20,
    head: {
      date: 'date',
      bet: 'bet',
      win: 'win',
      gameId: 'Game ID',
    },
  },
  payTableData: [
    {
      slug: ResourceTypes.wild,
      combos: [],
    },
    {
      slug: ResourceTypes.scatter,
      combos: [{ pattern: 'x3', multiplier: 100 }],
    },
    {
      slug: ResourceTypes.top1,
      combos: [
        { pattern: 'x5', multiplier: 750 },
        { pattern: 'x4', multiplier: 150 },
        { pattern: 'x3', multiplier: 50 },
      ],
    },
    {
      slug: ResourceTypes.top2,
      combos: [
        { pattern: 'x5', multiplier: 500 },
        { pattern: 'x4', multiplier: 100 },
        { pattern: 'x3', multiplier: 35 },
      ],
    },
    {
      slug: ResourceTypes.top3,
      combos: [
        { pattern: 'x5', multiplier: 300 },
        { pattern: 'x4', multiplier: 60 },
        { pattern: 'x3', multiplier: 25 },
      ],
    },
    {
      slug: ResourceTypes.top4,
      combos: [
        { pattern: 'x5', multiplier: 200 },
        { pattern: 'x4', multiplier: 40 },
        { pattern: 'x3', multiplier: 20 },
      ],
    },
    {
      slug: ResourceTypes.middle1,
      combos: [
        { pattern: 'x5', multiplier: 150 },
        { pattern: 'x4', multiplier: 25 },
        { pattern: 'x3', multiplier: 12 },
      ],
    },
    {
      slug: ResourceTypes.middle2,
      combos: [
        { pattern: 'x5', multiplier: 100 },
        { pattern: 'x4', multiplier: 20 },
        { pattern: 'x3', multiplier: 8 },
      ],
    },
    {
      slug: ResourceTypes.lowA,
      combos: [
        { pattern: 'x5', multiplier: 50 },
        { pattern: 'x4', multiplier: 10 },
        { pattern: 'x3', multiplier: 5 },
      ],
    },
    {
      slug: ResourceTypes.lowK,
      combos: [
        { pattern: 'x5', multiplier: 50 },
        { pattern: 'x4', multiplier: 10 },
        { pattern: 'x3', multiplier: 5 },
      ],
    },
    {
      slug: ResourceTypes.lowQ,
      combos: [
        { pattern: 'x5', multiplier: 25 },
        { pattern: 'x4', multiplier: 5 },
        { pattern: 'x3', multiplier: 2 },
      ],
    },
    {
      slug: ResourceTypes.lowJ,
      combos: [
        { pattern: 'x5', multiplier: 25 },
        { pattern: 'x4', multiplier: 5 },
        { pattern: 'x3', multiplier: 2 },
      ],
    },
    {
      slug: ResourceTypes.low10,
      combos: [
        { pattern: 'x5', multiplier: 25 },
        { pattern: 'x4', multiplier: 5 },
        { pattern: 'x3', multiplier: 2 },
      ],
    },
  ],
};

export enum SlotId {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  I = 'I',
  J = 'J',
  K = 'K',
  WL = 'WL',
  SC1 = 'SC1',
}
