import ViewContainer from '../components/container';

import type { PopupProps } from './d';

export abstract class Popup extends ViewContainer {
  constructor() {
    super();
    this.zIndex = 2;
  }

  public hide(): void {}

  public show(_props?: PopupProps): void {}
}
